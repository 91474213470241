import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { FdTypography } from '@fifthdomain/fe-shared';

const ChatMessageIndicator = ({ messageCount }) => {
  if (messageCount === 0) {
    return null;
  }

  return (
    <Box className="flex items-center gap-x-2">
      <Box
        className="h-2.5 w-2.5 rounded-full"
        sx={(theme) => ({ backgroundColor: theme.palette.primary.main })}
      />
      <FdTypography variant="captiontext2">{`${messageCount} unread message${
        messageCount > 1 ? 's' : ''
      }`}</FdTypography>
    </Box>
  );
};

ChatMessageIndicator.propTypes = {
  messageCount: PropTypes.number,
};

ChatMessageIndicator.defaultProps = {
  messageCount: 0,
};

export default ChatMessageIndicator;
