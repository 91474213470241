import React, { useState } from 'react';
import { Box } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { FdCard, FdTextField } from '@fifthdomain/fe-shared';
import ProfileView from './ProfileView';
import ProfileHeading from './ProfileHeading';
import { PROFILE_DEFAULT_VALUES, PROFILE_VIEW_VALUES } from '../../constants';

const SocialMedia = () => {
  const [editMode, setEditMode] = useState(false);
  const { control } = useFormContext();

  return (
    <FdCard
      heading={
        <ProfileHeading
          heading="Social Media"
          editMode={editMode}
          setEditMode={setEditMode}
        />
      }
      data-cy="media"
    >
      {editMode ? (
        <Box>
          <Controller
            control={control}
            name="linkedIn"
            render={({ field: { ref, ...rest }, fieldState: { error } }) => (
              <Box mt={1} mb={3}>
                <FdTextField
                  id="linkedIn"
                  label="LinkedIn"
                  fullWidth
                  error={error}
                  helperText={error && error.message}
                  data-cy="linkedIn"
                  {...rest}
                  inputRef={ref}
                />
              </Box>
            )}
          />
          <Controller
            control={control}
            name="gitlab"
            render={({ field: { ref, ...rest }, fieldState: { error } }) => (
              <Box mb={3}>
                <FdTextField
                  id="gitlab"
                  label="GitHub/GitLab/Other Contribution Platform"
                  fullWidth
                  error={error}
                  helperText={error && error.message}
                  data-cy="gitlab"
                  {...rest}
                  inputRef={ref}
                />
              </Box>
            )}
          />
          <Controller
            control={control}
            name="website"
            render={({ field: { ref, ...rest }, fieldState: { error } }) => (
              <Box mb={3}>
                <FdTextField
                  id="website"
                  label="Website/Portfolio"
                  fullWidth
                  error={error}
                  helperText={error && error.message}
                  data-cy="website"
                  {...rest}
                  inputRef={ref}
                />
              </Box>
            )}
          />
        </Box>
      ) : (
        <ProfileView
          defaultValue={PROFILE_DEFAULT_VALUES[4]}
          title={PROFILE_VIEW_VALUES?.slice(0, 3)}
        />
      )}
    </FdCard>
  );
};

export default SocialMedia;
