import React from 'react';
import { Box } from '@mui/material';
import { FdTypography, FdButton, FdTooltip } from '@fifthdomain/fe-shared';
import { useMutation, gql } from '@apollo/client';
import { useFormContext } from 'react-hook-form';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import VisibilityOffIcon from '@mui/icons-material/VisibilityOff';
import PropTypes from 'prop-types';
import {
  getToolTipTitle,
  WORKING_ARRANGEMENT,
  GENDER,
  NEURODIVERSITY,
  RURAL_REMOTE,
  ETHNICITY,
} from '../../constants';
import {
  warningToastMessage,
  successToastMessage,
  errorToastMessage,
} from '../../shared/utils/toast';
import { getKeyByValue } from '../../shared/utils/objectUtils';
import {
  updateUser,
  createSocialMedia,
  updateSocialMedia,
} from '../../graphql/mutations';

const ProfileHeading = ({ heading, editMode, setEditMode }) => {
  const { getValues, trigger, reset } = useFormContext();

  const [updateUserMutation, { loading: updateUserLoading }] = useMutation(
    gql(updateUser),
    {
      onError: ({ graphQLErrors }) => {
        errorToastMessage(graphQLErrors[0]?.message);
      },
    },
  );
  const [createSocialMediaMutation] = useMutation(gql(createSocialMedia));
  const [updateSocialMediaMutation] = useMutation(gql(updateSocialMedia));

  return (
    <Box display="flex" justifyContent="space-between">
      <Box display="flex">
        {heading === 'Diversity' && (
          <FdTooltip position="top" title={getToolTipTitle(heading)}>
            <Box pr={1} pt={0.8}>
              <VisibilityOffIcon />
            </Box>
          </FdTooltip>
        )}
        <FdTypography variant="h3">{heading}</FdTypography>
        {heading !== 'Diversity' && (
          <FdTooltip position="top" title={getToolTipTitle(heading)}>
            <Box pl={1} pt={0.8}>
              <InfoOutlinedIcon />
            </Box>
          </FdTooltip>
        )}
      </Box>
      {editMode ? (
        <Box display="flex">
          <FdButton
            variant="secondary"
            size="small"
            onClick={() => {
              reset();
              setEditMode(false);
              warningToastMessage('Updates not saved');
            }}
            style={{
              marginRight: '8px',
            }}
          >
            CANCEL
          </FdButton>
          <FdButton
            variant="primary"
            size="small"
            onClick={async () => {
              const {
                id,
                relocate,
                salaryRange,
                workArrangement,
                gender,
                neurodiversity,
                ethnicity,
                remote,
                jobPool,
                socialMedias,
                linkedIn,
                gitlab,
                website,
              } = getValues();
              const userSocialMedia = heading === 'Social Media';

              if (heading !== 'Logistics') {
                if (userSocialMedia) {
                  if (linkedIn) {
                    const existingURL = socialMedias?.items?.find(
                      (item) => item?.type === 'LINKEDIN',
                    );
                    const socialMediaMuation = existingURL
                      ? updateSocialMediaMutation
                      : createSocialMediaMutation;
                    socialMediaMuation({
                      variables: {
                        input: existingURL
                          ? {
                              id: existingURL?.id,
                              type: 'LINKEDIN',
                              url: linkedIn,
                            }
                          : { type: 'LINKEDIN', url: linkedIn, userId: id },
                      },
                    });
                  }
                  if (gitlab) {
                    const existingURL = socialMedias?.items?.find(
                      (item) => item?.type === 'GITHUB',
                    );
                    const socialMediaMuation = existingURL
                      ? updateSocialMediaMutation
                      : createSocialMediaMutation;
                    socialMediaMuation({
                      variables: {
                        input: existingURL
                          ? {
                              id: existingURL?.id,
                              type: 'GITHUB',
                              url: gitlab,
                            }
                          : { type: 'GITHUB', url: gitlab, userId: id },
                      },
                    });
                  }
                  if (website) {
                    const existingURL = socialMedias?.items?.find(
                      (item) => item?.type === 'WEBSITE',
                    );
                    const socialMediaMuation = existingURL
                      ? updateSocialMediaMutation
                      : createSocialMediaMutation;
                    socialMediaMuation({
                      variables: {
                        input: existingURL
                          ? {
                              id: existingURL?.id,
                              type: 'WEBSITE',
                              url: website,
                            }
                          : { type: 'WEBSITE', url: website, userId: id },
                      },
                    });
                  }
                  setEditMode(false);
                  successToastMessage('Updates saved!');
                } else {
                  let input = {
                    id,
                  };
                  switch (heading) {
                    case 'Diversity':
                      input = {
                        ...input,
                        gender: getKeyByValue(GENDER, gender),
                        neurodiversity:
                          getKeyByValue(
                            NEURODIVERSITY,
                            neurodiversity?.value,
                          ) || getKeyByValue(NEURODIVERSITY, neurodiversity),
                        ethnicity: getKeyByValue(ETHNICITY, ethnicity),
                        remote:
                          getKeyByValue(RURAL_REMOTE, remote?.value) ||
                          getKeyByValue(RURAL_REMOTE, remote),
                      };
                      break;
                    case 'Job Pool':
                      input = {
                        ...input,
                        jobPool,
                      };
                      break;
                    default:
                      input = { ...input };
                      break;
                  }
                  updateUserMutation({
                    variables: {
                      input,
                    },
                    onCompleted: () => successToastMessage('Updates saved!'),
                  });
                }
                setEditMode(false);
              } else {
                const _salaryRangeTrigger = await trigger(['salaryRange']);

                if (_salaryRangeTrigger) {
                  updateUserMutation({
                    variables: {
                      input: {
                        id,
                        workArrangement:
                          getKeyByValue(
                            WORKING_ARRANGEMENT,
                            workArrangement?.value,
                          ) ||
                          getKeyByValue(WORKING_ARRANGEMENT, workArrangement),
                        relocate,
                        minSalary: salaryRange[0],
                        maxSalary: salaryRange[1],
                      },
                    },
                    onCompleted: () => successToastMessage('Updates saved!'),
                  });
                  setEditMode(false);
                }
              }
            }}
          >
            {updateUserLoading ? 'SAVING...' : 'SAVE'}
          </FdButton>
        </Box>
      ) : (
        <FdButton
          variant="primary"
          size="small"
          onClick={() => setEditMode(true)}
        >
          Edit
        </FdButton>
      )}
    </Box>
  );
};

ProfileHeading.propTypes = {
  heading: PropTypes.oneOfType([PropTypes.element, PropTypes.string]),
  editMode: PropTypes.bool,
  setEditMode: PropTypes.func,
};

ProfileHeading.defaultProps = {
  heading: '',
  editMode: false,
  setEditMode: () => {},
};

export default ProfileHeading;
