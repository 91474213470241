import React from 'react';
import PropTypes from 'prop-types';
import _ from 'lodash';
import { isWithinInterval, subMonths, format } from 'date-fns';
import { gql } from '@apollo/client';
import { Box } from '@mui/material';
import {
  useQueryRecursive,
  FdTypography,
  FdCortex,
  getCortexLevels,
  cortexSort,
} from '@fifthdomain/fe-shared';
import { listTaskAttemptAggregatesByUserId } from '../../../graphql/queries';
import useGetSkills from '../../../hooks/useGetSkills';
import { PROFICIENCY, PROFICIENCY_LEVELS } from '../../../constants';
import { getLabelColor } from '../../../shared/utils/layout';

const degradationRate = {
  1: 2,
  2: 3,
  3: 5,
  4: 8,
  5: 13,
};

const CyberBrain = ({ userId, timeLine }) => {
  const { skills, skillsLoading } = useGetSkills();
  const {
    data: listTaskAttemptAggregatesByUserIdData,
    loading: listTaskAttemptAggregatesByUserIdLoading,
  } = useQueryRecursive(gql(listTaskAttemptAggregatesByUserId), {
    variables: {
      userId,
      limit: 1000,
    },
    skip: !userId,
  });

  const specialties = _(skills)
    .groupBy('specialtyId')
    .map((items, specialtyId) => ({
      specialtyId,
      specialtyName: items[0]?.specialty?.name,
      items,
    }))
    .value();

  const listTaskAttemptAggregates =
    listTaskAttemptAggregatesByUserIdData?.listTaskAttemptAggregatesByUserId
      ?.items || [];
  const attemptsData = listTaskAttemptAggregates?.filter(
    (la) => la?.task?.skills?.items?.length > 0,
  );
  const attemptsDataUnique = _.uniqBy(attemptsData, 'taskId');
  const attemptsDataTimeLineBased = timeLine
    ? attemptsDataUnique?.filter((a) =>
        isWithinInterval(new Date(a?.updatedAt), {
          start: new Date(timeLine?.startDate),
          end: new Date(timeLine?.endDate),
        }),
      )
    : attemptsDataUnique;

  const onlySolves = attemptsDataTimeLineBased?.filter(
    (a) => a?.status === 'COMPLETED',
  );

  // logic for level solves/effort
  // Ln = All solves/effort in Sum (Ln.. + L5)
  const getLevelPoints = (_level, _attempts) => {
    const levelEndDate2 = new Date();
    const levelStartDate2 = subMonths(levelEndDate2, degradationRate[_level]);

    return (
      _attempts?.filter(
        (a) =>
          a?.task?.difficulty === _level &&
          isWithinInterval(new Date(a?.updatedAt), {
            start: levelStartDate2,
            end: levelEndDate2,
          }),
      )?.length || 0
    );
  };

  const getDegradationScore = (_level, _attempts) => {
    let finalScore = 0;
    for (let index = _level; index <= 5; index += 1) {
      const levelEndDate = new Date();
      const levelStartDate = subMonths(levelEndDate, degradationRate[index]);
      const scoreInTimeFrame =
        _attempts?.filter(
          (a) =>
            a?.task?.difficulty === index &&
            isWithinInterval(new Date(a?.updatedAt), {
              start: levelStartDate,
              end: levelEndDate,
            }),
        )?.length || 0;
      finalScore += scoreInTimeFrame;
    }
    return finalScore > 0 ? finalScore : 0;
  };

  const specialtiesFormatted =
    specialties?.sort(cortexSort)?.map((specialty, idx) => {
      const { levels = [], flip = false } = getCortexLevels(idx);
      const dataSorted = specialty?.items?.map((skill) => {
        const onlySolvesUniqueByTaskId = _.uniqBy(onlySolves, 'taskId');
        const attempts =
          onlySolvesUniqueByTaskId?.filter((a) =>
            a?.task.skills.items?.some((s) =>
              s?.skill?.alias?.includes(skill?.alias),
            ),
          ) || [];
        const lastSolveDate = _.maxBy(
          attempts,
          (item) => new Date(item.updatedAt),
        )?.updatedAt;
        const lastSolveDateFormatted = lastSolveDate
          ? format(new Date(lastSolveDate), 'dd/MM/yyyy')
          : 'N/A';

        return {
          id: skill?.alias,
          data: [
            ...(levels?.map((l) => {
              const levelPoints = getLevelPoints(l, attempts) || undefined;
              const yValue = getDegradationScore(l, attempts) || 0;
              return {
                x: l,
                yAlias: skill?.alias,
                yValue,
                y: yValue,
                yHideValue: !levelPoints,
                yValueDisplay: levelPoints || 0,
                yType: 'solves',
                yLevelPoints:
                  attempts?.filter((a) => a?.task?.difficulty === l)?.length ||
                  0,
                yLabelName: `${skill?.name} (${skill?.alias})`,
                yToolTip: (
                  <Box className="flex flex-col my-1">
                    <Box>
                      <strong>Skill: </strong>
                      {`${skill?.name} (${skill?.alias})`}
                    </Box>
                    <Box>
                      <strong>Proficiency : </strong>
                      {`Level ${l} (${PROFICIENCY_LEVELS[l]})`}
                    </Box>
                    <Box>
                      <strong>{'Total Solved Challenges : '}</strong>
                      {levelPoints || 0}
                    </Box>
                    <Box>
                      <strong>{'Last Solve : '}</strong>
                      {levelPoints ? lastSolveDateFormatted : 'N/A'}
                    </Box>
                  </Box>
                ),
              };
            }) || []),
          ],
        };
      });
      return { ...specialty, flip, levels, dataSorted };
    }) || [];

  const topSpecialties = specialtiesFormatted?.map((s) => {
    const proficiencyLevelOfSolves = s?.dataSorted?.reduce(
      (acc, i) =>
        // eslint-disable-next-line no-unsafe-optional-chaining
        acc + i?.data?.reduce((dAcc, d) => dAcc + (d.y !== 0 ? 1 : 0), 0),
      0,
    );
    const skillsCovered = s?.dataSorted?.reduce(
      // eslint-disable-next-line no-unsafe-optional-chaining
      (acc, i) => acc + i?.data?.reduce((dAcc, d) => dAcc + (d.y ? 1 : 0), 0),
      0,
    );
    return {
      specialty: s?.specialtyName,
      score: (1 / 3) * proficiencyLevelOfSolves + (2 / 3) * skillsCovered,
      dataSorted: s?.dataSorted,
    };
  });

  const topSpecialty =
    _.orderBy(
      topSpecialties?.filter((ts) => ts?.score > 0),
      ['score', 'specialty'],
      ['desc', 'asc'],
    )?.[0]?.specialty || '';

  const allTopSkills = topSpecialties
    ?.map((ts) =>
      ts?.dataSorted?.map((d) => ({
        id: d?.id,
        // eslint-disable-next-line no-unsafe-optional-chaining
        score: d?.data?.reduce((acc, i) => acc + i?.y, 0),
        topRow: _.maxBy(
          d?.data?.filter((ds) => ds?.y > 0),
          'x',
        ),
      })),
    )
    ?.flat()
    ?.filter((x) => x?.score > 0);

  const top3Skills =
    _.orderBy(allTopSkills, ['score', 'id'], ['desc', 'asc']).slice(0, 3) || [];
  return (
    <Box id="cyber-brain" className="flex flex-col items-center justify-center">
      <FdCortex
        specialties={specialtiesFormatted}
        loading={skillsLoading || listTaskAttemptAggregatesByUserIdLoading}
      />
      <Box className="flex items-start gap-x-4 mt-4">
        <Box>
          <FdTypography
            variant="captiontext1"
            style={{ fontWeight: 700, marginBottom: '1rem' }}
          >
            Most Aligned Professional Specialty
          </FdTypography>
          {topSpecialty ? (
            <Box>
              <FdTypography variant="captiontext2" color="secondary">
                You currently have most alignment with the following
                Professional Specialty:
              </FdTypography>
              <Box mt={1}>
                <Box className="mb-3">
                  <FdTypography variant="captiontext2">
                    <span
                      style={{
                        padding: '4px 8px',
                        borderRadius: '4px',
                        backgroundColor: 'rgba(227, 230, 236, 1)',
                        color: 'rgba(0, 0, 0, 1)',
                        fontWeight: 600,
                      }}
                    >
                      {topSpecialty}
                    </span>
                  </FdTypography>
                </Box>
              </Box>
            </Box>
          ) : (
            <Box>
              <FdTypography variant="captiontext2" color="secondary">
                As you embark on solving challenges during
                competitions/assessments, the Professional Specialty most
                closely aligned with your skills, will dynamically update.
              </FdTypography>
            </Box>
          )}
        </Box>
        <Box>
          <FdTypography
            variant="captiontext1"
            style={{ fontWeight: 700, marginBottom: '1rem' }}
          >
            Top Skills Demonstrated
          </FdTypography>
          {top3Skills?.length > 0 ? (
            <Box>
              <FdTypography variant="captiontext2" color="secondary">
                You have demonstrated strong abilities in the following skills:
              </FdTypography>
              <Box mt={1}>
                {top3Skills?.map((ts) => {
                  const { yLabelName, x } = ts?.topRow || {};
                  const proficiency = PROFICIENCY_LEVELS[x];
                  const proficiencyColor = PROFICIENCY[proficiency]?.color;
                  return (
                    <Box my={2}>
                      <FdTypography
                        variant="captiontext1"
                        style={{ fontWeight: 600 }}
                      >
                        {yLabelName}
                      </FdTypography>
                      <Box className="flex my-2">
                        <FdTypography variant="captiontext2">
                          <span
                            style={{
                              padding: '3px 8px',
                              borderRadius: '4px',
                              backgroundColor: proficiencyColor,
                              color: getLabelColor(proficiencyColor),
                              fontWeight: 600,
                            }}
                          >
                            {proficiency}
                          </span>
                        </FdTypography>
                      </Box>
                    </Box>
                  );
                })}
              </Box>
            </Box>
          ) : (
            <Box>
              <FdTypography variant="captiontext2" color="secondary">
                As you engage in solving challenges within
                competitions/assessments, your top demonstrated skills will
                dynamically update
              </FdTypography>
            </Box>
          )}
        </Box>
      </Box>
    </Box>
  );
};

CyberBrain.defaultProps = {
  userId: undefined,
  timeLine: undefined,
};

CyberBrain.propTypes = {
  userId: PropTypes.string,
  timeLine: PropTypes.shape({
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string.isRequired,
  }),
};

export default CyberBrain;
