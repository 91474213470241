import React from 'react';
import PropTypes from 'prop-types';
import { Box, IconButton } from '@mui/material';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import { FdTooltip, FdTypography } from '@fifthdomain/fe-shared';

const LabelWithTooltip = ({ label, tooltipText, labelVariant }) => {
  const fontWeight = labelVariant ? 500 : 400;
  return (
    <Box className="flex items-center">
      <FdTypography variant={labelVariant} style={{ fontWeight }}>
        {label}
      </FdTypography>
      <FdTooltip title={tooltipText}>
        <IconButton size="small" style={{ marginLeft: '5px' }}>
          <InfoOutlinedIcon />
        </IconButton>
      </FdTooltip>
    </Box>
  );
};

LabelWithTooltip.defaultProps = {
  labelVariant: 'subtitle2',
};

LabelWithTooltip.propTypes = {
  label: PropTypes.node.isRequired,
  tooltipText: PropTypes.node.isRequired,
  labelVariant: PropTypes.string,
};

export default LabelWithTooltip;
