import React from 'react';
import { Box, useTheme } from '@mui/material';
import PropTypes from 'prop-types';
import Chart from 'react-apexcharts';

const Gauge = ({
  data,
  labels,
  chartId,
  percentageSymbol,
  enableAnimations,
}) => {
  const theme = useTheme();
  const options = {
    series: data,
    options: {
      plotOptions: {
        radialBar: {
          startAngle: -90,
          endAngle: 90,
          hollow: {
            margin: 10,
            size: '60%',
            background: 'transparent',
          },
          dataLabels: {
            name: {
              show: false,
            },
            value: {
              show: false,
            },
          },
        },
      },
      colors: ['#2495fd', '#556bab'],
      labels: labels.length
        ? labels
        : data.map((d) => `${d}${percentageSymbol}`),
      chart: {
        id: chartId,
        redrawOnParentResize: true,
        background: 'transparent',
      },
      tooltip: {
        enabled: true,
        fillSeriesColor: false,
        style: {
          fontSize: '14px',
        },
        custom: ({ seriesIndex, w }) =>
          `<div class="arrow_box" style="padding:10px;font-weight:bold">
            <span>
              <span style="height:12px;width:12px;background-color:${w.globals.colors[seriesIndex]};border-radius:50%;margin-right:5px;display:inline-block">
              </span>
              <span>${w.globals.labels[seriesIndex]}</span>
            </span>
          </div>`,
      },
      animations: {
        enabled: enableAnimations,
      },
      theme: {
        mode: theme?.palette?.type || 'light',
      },
    },
  };

  return (
    <Box data-cy={`gauge-chart-${chartId}`}>
      <Chart
        options={options.options}
        series={options.series}
        type="radialBar"
        width="450"
      />
    </Box>
  );
};

Gauge.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  ),
  labels: PropTypes.arrayOf(PropTypes.string),
  chartId: PropTypes.string,
  percentageSymbol: PropTypes.string,
  enableAnimations: PropTypes.bool,
};

Gauge.defaultProps = {
  data: [],
  labels: [],
  chartId: 'gauge-chart',
  percentageSymbol: '',
  enableAnimations: true,
};

export default Gauge;
