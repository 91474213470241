import React from 'react';
import { gql, useQuery } from '@apollo/client';
import { Box, useTheme } from '@mui/material';
import SpokeIcon from '@mui/icons-material/Spoke';
import {
  FdTypography,
  FdSkeleton,
  useSnapshot,
  globalStore,
  FdTooltip,
} from '@fifthdomain/fe-shared';
import { listSquadMembersByUserId } from '../../graphql/queries';

const SquadTag = () => {
  const theme = useTheme();
  const globalSnap = useSnapshot(globalStore);

  const { data: squadMemberData, loading: squadMemberLoading } = useQuery(
    gql(listSquadMembersByUserId),
    {
      variables: {
        userId: globalSnap?.userId,
      },
    },
  );
  const squadMember =
    squadMemberData?.listSquadMembersByUserId?.items?.find(
      (s) => s.squad && s?.role !== 'VIEWER',
    ) || {};
  const squadName = squadMember?.squad?.name || '';

  return (
    <FdSkeleton height={24} loading={squadMemberLoading} width={200}>
      <Box>
        {squadName && (
          <FdTooltip
            title={`
          You are currently a member of ${squadName}. Squad Managers and Squad Viewers can see your skill and performance data, and how it contributes to this squad. 
          Squad members without management abilities will not be able to view your individual skill and performance data.`}
          >
            <Box
              className="flex items-center px-4 py-1 rounded gap-x-2"
              style={{
                height: '28px',
                backgroundColor:
                  theme?.palette?.type === 'dark'
                    ? '#20228F'
                    : 'rgba(227, 230, 236, 1)',
              }}
            >
              <SpokeIcon style={{ height: '16px', width: '16px' }} />
              <FdTypography variant="captiontext1" style={{ fontWeight: 500 }}>
                {squadName}
              </FdTypography>
            </Box>
          </FdTooltip>
        )}
      </Box>
    </FdSkeleton>
  );
};

export default SquadTag;
