import React from 'react';
import PropTypes from 'prop-types';
import { Box, Badge, useTheme } from '@mui/material';
import ChatBubbleOutlineIcon from '@mui/icons-material/ChatBubbleOutline';
import {
  FdDelayed,
  FdTable,
  FdTypography,
  FdTooltip,
  FdChip,
  FdButton,
} from '@fifthdomain/fe-shared';
import CardProgressBar from './CardProgressBar';
import { productStyle } from '../../shared/utils/layout';
import { getParticipantStatus } from '../../shared/utils/getParticipantStatus';
import { getParticipantStatusColor } from '../../shared/utils/getStatusColor';
import { getDateTimeZoneFormatted } from '../../shared/utils/dateUtils';
import ChatIndicator from './ChatIndicator';
import ChatMessageIndicator from './ChatIndicator';

const AssignedContentGrid = ({ data, onEnterClick, eventStatus }) => {
  const theme = useTheme();
  const columns = [
    {
      field: 'eventType',
      headerName: 'Event Type',
      valueGetter: (params) => params.row.contentType,
    },
    {
      field: 'eventName',
      flex: 2.2,
      headerName: 'Event Name',
      valueGetter: (params) => params.row.title,
      renderCell: (params) => {
        const { row } = params;
        return (
          <Box display="flex" minWidth="391px">
            <Box
              display="inline-block"
              ml={1}
              style={{
                ...productStyle(row.contentType?.toUpperCase(), theme),
                paddingTop: 0,
              }}
            >
              {row.icon}
            </Box>
            <Box className="flex flex-col justify-around ml-3">
              <Box width={391}>
                <FdTooltip title={row.title?.length > 40 ? row.title : ''}>
                  <span>
                    <FdTypography
                      variant="body2"
                      fontWeight="medium"
                      className="overflow-hidden whitespace-nowrap overflow-ellipsis mb-1"
                    >
                      {row.title}
                    </FdTypography>
                  </span>
                </FdTooltip>
              </Box>
              <Box className="flex items-center">
                {row.contentType === 'course' && row.courseModulesCount > 0 && (
                  <Box
                    display="inline-block"
                    ml={1}
                    style={{
                      ...productStyle('COURSE', theme),
                      padding: '1px 4px',
                    }}
                  >
                    <FdTypography variant="captiontext2" color="black">
                      {`${row.courseModulesCount} lessons`}
                    </FdTypography>
                  </Box>
                )}
                {row.contentType === 'assessment' && (
                  <Box display="flex" ml={1}>
                    <span
                      style={{
                        ...productStyle('ASSESSMENT', theme),
                        padding: '1px 4px',
                      }}
                    >
                      <FdTypography variant="captiontext2" color="black">
                        {`Duration: ${row.duration}`}
                      </FdTypography>
                    </span>
                  </Box>
                )}
                {row.contentType === 'competition' && (
                  <Box display="flex" ml={1}>
                    <span
                      style={{
                        ...productStyle('COMPETITION', theme),
                        padding: '1px 4px',
                      }}
                    >
                      <FdTypography variant="captiontext2" color="black">
                        {`${row.type} Competition`}
                      </FdTypography>
                    </span>
                  </Box>
                )}
                {row.contentType === 'training' && (
                  <Box className="flex gap-x-1">
                    <Box display="flex">
                      <span
                        style={{
                          ...productStyle('TRAINING', theme),
                          padding: '1px 4px',
                          marginRight: '8px',
                        }}
                      >
                        <FdTypography variant="captiontext2" color="black">
                          Training
                        </FdTypography>
                      </span>
                    </Box>
                    <Box display="flex">
                      <span
                        style={{
                          ...productStyle('TRAINING', theme),
                          padding: '1px 4px',
                          marginRight: '8px',
                        }}
                      >
                        <FdTypography variant="captiontext2" color="black">
                          {`${row?.tasksCount} Challenges`}
                        </FdTypography>
                      </span>
                    </Box>
                  </Box>
                )}
                {row.contentType === 'fd-training' && (
                  <Box display="flex" ml={1}>
                    <span
                      style={{
                        ...productStyle('TRAINING', theme),
                        padding: '1px 4px',
                      }}
                    >
                      <FdTypography variant="captiontext2" color="black">
                        {`${row?.tasksCount} Challenges`}
                      </FdTypography>
                    </span>
                  </Box>
                )}
              </Box>
            </Box>
          </Box>
        );
      },
    },
    {
      field: 'org',
      headerName: 'Host Organisation',
      flex: 1,
      valueGetter: (params) => params.row.organizer,
    },

    ...(eventStatus === 'available'
      ? [
          {
            field: 'lastActive',
            headerName: 'Last Activity at',
            flex: 1,
            valueGetter: (params) =>
              params.value && getDateTimeZoneFormatted(params.value, true),
            renderCell: (params) => {
              return params?.value || '-';
            },
          },
          {
            field: 'status',
            flex: 1,
            headerName: 'Your Progress',
            align: 'center',
            headerAlign: 'center',
            valueGetter: (params) =>
              params.row?.preComputedStatus
                ? params.row?.status
                : getParticipantStatus(params.row?.status),
            renderCell: (params) => {
              const { row } = params;
              return (
                <Box
                  mx={1}
                  className="flex flex-col items-center justify-center"
                >
                  {row.timestamp && (
                    <FdTypography
                      variant="captiontext2"
                      style={{ marginTop: '8px' }}
                    >
                      {row.timestamp}
                    </FdTypography>
                  )}
                  <FdChip
                    color={getParticipantStatusColor(
                      row?.status?.toUpperCase(),
                    )}
                    style={{ marginTop: '8px', marginBottom: '8px' }}
                    size="small"
                    label={
                      row?.preComputedStatus
                        ? row?.status
                        : getParticipantStatus(row?.status)
                    }
                  />
                </Box>
              );
            },
          },
          {
            field: 'progress',
            flex: 1,
            unSort: true,
            filterable: false,
            headerAlign: 'center',
            headerName: 'Progress',
            valueGetter: (params) => params.value,
            renderCell: (params) => {
              return <CardProgressBar content={params?.row} />;
            },
          },
          {
            field: 'unReadMessageCount',
            maxWidth: 150,
            unSort: true,
            headerAlign: 'center',
            filterable: false,
            align: 'center',
            headerName: '',
            valueGetter: (params) => params.row.unReadMessageCount,
            renderCell: (params) => {
              return (
                params.row.contentType === 'course' &&
                params.row.unReadMessageCount > 0 && (
                  <ChatMessageIndicator
                    messageCount={params.row.unReadMessageCount}
                  />
                )
              );
            },
          },
        ]
      : eventStatus === 'upcoming'
      ? [
          {
            field: 'startDate',
            headerName: 'Starting at',
            flex: 1,
            valueGetter: ({ row }) => row?.timestamp,
            renderCell: ({ row }) => row?.timestamp,
          },
        ]
      : [
          {
            field: 'endDate',
            headerName: 'Ended at',
            flex: 1,
            valueGetter: ({ row }) => row?.finishedOn && row?.timestamp,
            renderCell: ({ row }) => row?.timestamp,
          },
        ]),
  ];

  return (
    <FdDelayed height={810} triggerField={data} showSkeleton>
      <Box height={810} style={{ overflowY: 'auto' }}>
        <FdTable
          toolbarSettings={{
            title: '',
            filterButton: true,
            searchBox: true,
          }}
          columnVisibilityModel={{
            eventType: false,
            unReadMessageCount:
              data?.filter((d) => d?.unReadMessageCount > 0).length > 0 ||
              false,
          }}
          defaultMuiToolbarSettings={{
            showMuiDefaultToolbar: true,
            columnsButton: false,
            filterButton: true,
            densityButton: false,
            exportButton: false,
          }}
          actions={[
            {
              // eslint-disable-next-line react/no-unstable-nested-components
              CustomElement: (row) => {
                const {
                  contentType,
                  status,
                  assessment,
                  eventStatus: _eventStatus,
                } = row?.rowData || {};
                return (
                  <FdButton
                    variant="primary"
                    onClick={() => onEnterClick(row?.rowData)}
                    disabled={
                      (['assessment', 'training', 'fd-training'].includes(
                        contentType,
                      ) &&
                        !['STARTED', 'NOT_STARTED'].includes(status)) ||
                      (assessment?.guided && status === 'ENDED') ||
                      _eventStatus === 'ENDED'
                    }
                  >
                    Enter
                  </FdButton>
                );
              },
              width: 150,
            },
          ]}
          density="comfortable"
          rows={data || []}
          columns={columns}
          gridId="assigned-content"
          tablePageSize={10}
        />
      </Box>
    </FdDelayed>
  );
};

AssignedContentGrid.propTypes = {
  data: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  onEnterClick: PropTypes.func.isRequired,
  eventStatus: PropTypes.string.isRequired,
};

export default AssignedContentGrid;
