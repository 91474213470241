import React, { useState } from 'react';
import { Box } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import {
  FdCard,
  FdSelect,
  FdRadioGroup,
  FdDelayed,
} from '@fifthdomain/fe-shared';
import {
  ETHNICITY,
  GENDER,
  NEURODIVERSITY,
  RURAL_REMOTE,
  PROFILE_DEFAULT_VALUES,
  PROFILE_VIEW_VALUES,
} from '../../constants';
import ProfileView from './ProfileView';
import ProfileHeading from './ProfileHeading';

const Diversity = () => {
  const [editMode, setEditMode] = useState(false);
  const { control } = useFormContext();
  return (
    <FdCard
      heading={
        <ProfileHeading
          heading="Diversity"
          editMode={editMode}
          setEditMode={setEditMode}
        />
      }
      data-cy="diversity"
    >
      {editMode ? (
        <Box>
          <Controller
            control={control}
            name="gender"
            render={({ field, fieldState: { error } }) => (
              <Box mt={1} mb={2}>
                <FdSelect
                  id="gender"
                  label="Gender"
                  options={Object.values(GENDER)}
                  error={error}
                  helperText={error && error.message}
                  fullWidth
                  defaultSelected={field?.value}
                  {...field}
                />
              </Box>
            )}
          />
          <Controller
            control={control}
            name="neurodiversity"
            render={({
              field: { ref, value: fieldValue, ...rest },
              fieldState: { error },
            }) => (
              <FdDelayed>
                <Box mt={2} ml={1}>
                  <FdRadioGroup
                    id="neurodiversity"
                    label="Neurodiversity"
                    options={Object.values(NEURODIVERSITY)}
                    defaultValue={fieldValue}
                    fullWidth
                    error={error && error.message}
                    data-cy="neurodiversity"
                    {...rest}
                    inputRef={ref}
                  />
                </Box>
              </FdDelayed>
            )}
          />
          <Controller
            control={control}
            name="ethnicity"
            render={({ field, fieldState: { error } }) => (
              <Box mt={1} mb={2}>
                <FdSelect
                  id="ethnicity"
                  label="Ethnicity"
                  options={Object.values(ETHNICITY)}
                  error={error}
                  helperText={error && error.message}
                  fullWidth
                  defaultSelected={field?.value}
                  {...field}
                />
              </Box>
            )}
          />
          <Controller
            control={control}
            name="remote"
            render={({
              field: { ref, value: fieldValue, ...rest },
              fieldState: { error },
            }) => (
              <FdDelayed>
                <Box mt={2} ml={1}>
                  <FdRadioGroup
                    id="remote"
                    label="Rural/Remote"
                    options={Object.values(RURAL_REMOTE)}
                    defaultValue={fieldValue?.value}
                    fullWidth
                    error={error && error.message}
                    data-cy="remote"
                    {...rest}
                    inputRef={ref}
                  />
                </Box>
              </FdDelayed>
            )}
          />
        </Box>
      ) : (
        <ProfileView
          defaultValue={PROFILE_DEFAULT_VALUES[5]}
          title={PROFILE_VIEW_VALUES?.slice(3, 7)}
        />
      )}
    </FdCard>
  );
};

export default Diversity;
