/* eslint-disable */
// this is an auto generated file. This will be overwritten

export const listCourseUsers = /* GraphQL */ `
  query ListCourseUser(
    $filter: ModelCourseUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCourseUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        courseId
        lastActive
        exerciseProgresses {
          items {
            lessonId
            status
          }
        }
        messages(filter: { seen: { eq: FALSE } }) {
          items {
            id
            messages {
              type
              content
            }
            userCourseId
            receiverId
            createdAt
          }
        }
        course {
          orgId
          user {
            id
            org {
              name
            }
          }
          name
          description
          status
          availability
          categoryId
          category {
            name
          }
          image {
            bucket
            region
            key
          }
          available
          ownerId
          courseLessons {
            items {
              id
            }
          }
          courseUsers {
            items {
              id
              userId
            }
            nextToken
          }
          id
          createdAt
          updatedAt
        }
        userId
        orgId
        user {
          id
          name
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        status
        courseProgress
        id
        createdAt
        updatedAt
        courseCourseUsersId
      }
      nextToken
    }
  }
`;

export const getSystemTime = /* GraphQL */ `
  query GetSystemTime {
    getSystemTime
  }
`;

export const listLabInstancesRemainingTime = /* GraphQL */ `
  query ListLabInstances(
    $filter: ModelLabInstanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLabInstances(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        labPrototypeId
        userId
        runningTime
      }
      nextToken
    }
  }
`;

export const listModulePartProgressesByCourseUserId = /* GraphQL */ `
  query ListModulePartProgressesByCourseUserId(
    $courseUserId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelModulePartProgressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listModulePartProgressesByCourseUserId(
      courseUserId: $courseUserId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        courseUserId
        courseUser {
          courseId
          userId
          orgId
          status
          courseProgress
          id
          createdAt
          updatedAt
          courseCourseUsersId
        }
        modulePartId
        modulePart {
          name
          description
          courseModuleId
          expiry
          type
          quizId
          labId
          url
          id
          createdAt
          updatedAt
          courseModulePartsId
        }
        status
        startedOn
        finishedOn
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listModulePartProgresses = /* GraphQL */ `
  query ListModulePartProgresses(
    $filter: ModelModulePartProgressFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listModulePartProgresses(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        courseUserId
        courseUser {
          courseId
          userId
          orgId
          status
          courseProgress
          id
          createdAt
          updatedAt
          courseCourseUsersId
        }
        modulePartId
        modulePart {
          name
          description
          courseModuleId
          type
          quizId
          labId
          deletionMode
          url
          id
          createdAt
          updatedAt
          courseModulePartsId
        }
        status
        startedOn
        finishedOn
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const getAssessment = /* GraphQL */ `
  query GetAssessment($id: ID!) {
    getAssessment(id: $id) {
      id
      orgId
      name
      description
      teamBased
      startDateTime
      endDateTime
      hours
      minutes
      tasks {
        items {
          id
          assessmentId
          taskId
          createdAt
          updatedAt
        }
        nextToken
      }
      preMessage
      postMessage
      createdBy
      participantEventType
      creator {
        id
        email
        name
        phone
        ageBracket
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      groups {
        items {
          groupId
          assessmentId
          status
          startedOn
          finishedOn
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listAssessments = /* GraphQL */ `
  query ListAssessments(
    $filter: ModelAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssessments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        orgId
        name
        description
        teamBased
        startDateTime
        endDateTime
        hours
        minutes
        tasks {
          nextToken
        }
        preMessage
        postMessage
        createdBy
        participantEventType
        creator {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTask = /* GraphQL */ `
  query GetTask($id: ID!) {
    getTask(id: $id) {
      id
      name
      description
      externalId
      category
      difficulty
      recommendedPoints
      estimatedSolveTime
      solution
      competencies {
        items {
          id
          competencyId
          taskId
          createdAt
          updatedAt
        }
        nextToken
      }
      assessments {
        items {
          id
          assessmentId
          taskId
          modulePartId
          modulePart {
            name
            description
            courseModuleId
            type
            duration
            expiry
            inactivityExpiry
            initialLabCount
            minLabCount
            deletionMode
            labId
          }
          createdAt
          updatedAt
        }
        nextToken
      }
      answer
      files {
        items {
          id
          taskId
          url
          name
          size
          type
          createdAt
          updatedAt
        }
        nextToken
      }
      hints {
        items {
          id
          taskId
          text
          createdAt
          updatedAt
        }
        nextToken
      }
      userId
      user {
        id
        email
        name
        phone
        ageBracket
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      status
      history {
        items {
          id
          taskId
          userId
          message
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listTasks = /* GraphQL */ `
  query ListTasks(
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasks(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        type
        labId
        lab {
          name
        }
        externalId
        category
        difficulty
        recommendedPoints
        estimatedSolveTime
        solution
        competencies {
          nextToken
        }
        assessments {
          nextToken
        }
        answer
        files {
          nextToken
        }
        hints {
          nextToken
        }
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
          org {
            id
            name
          }
        }
        status
        history {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getHistory = /* GraphQL */ `
  query GetHistory($id: ID!) {
    getHistory(id: $id) {
      id
      taskId
      userId
      message
      createdAt
      updatedAt
    }
  }
`;
export const listHistories = /* GraphQL */ `
  query ListHistories(
    $filter: ModelHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHistories(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        taskId
        userId
        message
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getFile = /* GraphQL */ `
  query GetFile($id: ID!) {
    getFile(id: $id) {
      id
      taskId
      url
      name
      size
      type
      file {
        bucket
        key
        region
      }
      createdAt
      updatedAt
    }
  }
`;
export const listFiles = /* GraphQL */ `
  query ListFiles(
    $filter: ModelFileFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listFiles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        taskId
        url
        name
        size
        type
        file {
          bucket
          key
          region
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getHint = /* GraphQL */ `
  query GetHint($id: ID!) {
    getHint(id: $id) {
      id
      taskId
      text
      createdAt
      updatedAt
    }
  }
`;
export const listHints = /* GraphQL */ `
  query ListHints(
    $filter: ModelHintFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listHints(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        taskId
        text
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserAssessment = /* GraphQL */ `
  query GetUserAssessment($id: ID!) {
    getUserAssessment(id: $id) {
      id
      userId
      user {
        id
        email
        name
        phone
        ageBracket
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      assessment {
        id
        orgId
        name
        description
        teamBased
        startDateTime
        endDateTime
        hours
        minutes
        tasks {
          nextToken
        }
        preMessage
        postMessage
        createdBy
        participantEventType
        creator {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      userAssessmentAssessmentId
      groupId
      status
      startedOn
      finishedOn
      createdAt
      updatedAt
    }
  }
`;
export const listUserAssessments = /* GraphQL */ `
  query ListUserAssessments(
    $filter: ModelUserAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserAssessments(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        assessment {
          levels {
            items {
              tasks {
                items {
                  task {
                    recommendedPoints
                  }
                }
              }
            }
          }
          tasks {
            items {
              task {
                recommendedPoints
              }
            }
          }
          id
          name
          status
          multiLevel
          participantEventType
          updatedAt
          startDateTime
          endDateTime
        }
        userAssessmentAssessmentId
        groupId
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTaskAttempt = /* GraphQL */ `
  query GetTaskAttempt($id: ID!) {
    getTaskAttempt(id: $id) {
      id
      userId
      task {
        id
        name
        description
        externalId
        category
        difficulty
        recommendedPoints
        estimatedSolveTime
        solution
        competencies {
          nextToken
        }
        assessments {
          nextToken
        }
        answer
        files {
          nextToken
        }
        hints {
          nextToken
        }
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        status
        history {
          nextToken
        }
        createdAt
        updatedAt
      }
      userAssessment {
        id
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        assessment {
          id
          orgId
          name
          description
          teamBased
          startDateTime
          endDateTime
          hours
          minutes
          preMessage
          postMessage
          createdBy
          participantEventType
          createdAt
          updatedAt
        }
        userAssessmentAssessmentId
        groupId
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      taskAttemptUserAssessmentId
      assessmentId
      success
      firstBlood
      answer
      createdAt
      updatedAt
      taskAttemptTaskId
    }
  }
`;
export const listLabInstances = /* GraphQL */ `
  query ListLabInstances(
    $filter: ModelLabInstanceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listLabInstances(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        labPrototypeId
        status
        modulePartId
        userId
        userAssessmentId
        expiry
        deletedAt
        createdAt
        updatedAt
        modulePartLabInstancesId
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listTaskAttempts = /* GraphQL */ `
  query ListTaskAttempts(
    $filter: ModelTaskAttemptFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskAttempts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        task {
          id
          name
          description
          externalId
          category
          difficulty
          recommendedPoints
          estimatedSolveTime
          solution
          answer
          userId
          status
          createdAt
          updatedAt
        }
        userAssessment {
          id
          userId
          userAssessmentAssessmentId
          groupId
          status
          startedOn
          finishedOn
          createdAt
          updatedAt
        }
        taskAttemptUserAssessmentId
        assessmentId
        success
        firstBlood
        answer
        createdAt
        updatedAt
        taskAttemptTaskId
      }
      nextToken
    }
  }
`;
export const getVmConsole = /* GraphQL */ `
  query GetVmConsole($labInstanceVmId: ID!) {
    getVmConsole(labInstanceVmId: $labInstanceVmId) {
      location
      type
      url
      name
      keepAwakeUrl
    }
  }
`;
export const getLabTime = /* GraphQL */ `
  query GetLabTime($labInstanceId: ID!) {
    getLabTime(labInstanceId: $labInstanceId) {
      timeInLab
      timeRemaining
    }
  }
`;
export const getLabPrototype = /* GraphQL */ `
  query GetLabPrototype($id: ID!) {
    getLabPrototype(id: $id) {
      id
      name
      description
      provider
      status
      userId
      public
      shared
      payload
      vms {
        nextToken
        __typename
      }
      networks {
        nextToken
        __typename
      }
      instances {
        nextToken
        __typename
      }
      tasks {
        nextToken
        __typename
      }
      orgId
      createdAt
      updatedAt
      __typename
    }
  }
`;
export const getTaskOpened = /* GraphQL */ `
  query GetTaskOpened($id: ID!) {
    getTaskOpened(id: $id) {
      id
      userId
      task {
        id
        name
        description
        externalId
        category
        difficulty
        recommendedPoints
        estimatedSolveTime
        solution
        competencies {
          nextToken
        }
        assessments {
          nextToken
        }
        answer
        files {
          nextToken
        }
        hints {
          nextToken
        }
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        status
        history {
          nextToken
        }
        createdAt
        updatedAt
      }
      userAssessment {
        id
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        assessment {
          id
          orgId
          name
          description
          teamBased
          startDateTime
          endDateTime
          hours
          minutes
          preMessage
          postMessage
          createdBy
          participantEventType
          createdAt
          updatedAt
        }
        userAssessmentAssessmentId
        groupId
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      taskOpenedUserAssessmentId
      createdAt
      updatedAt
      taskOpenedTaskId
    }
  }
`;
export const listTaskOpeneds = /* GraphQL */ `
  query ListTaskOpeneds(
    $filter: ModelTaskOpenedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskOpeneds(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        task {
          id
          name
          description
          externalId
          category
          difficulty
          recommendedPoints
          estimatedSolveTime
          solution
          answer
          userId
          status
          createdAt
          updatedAt
        }
        userAssessment {
          id
          userId
          userAssessmentAssessmentId
          groupId
          status
          startedOn
          finishedOn
          createdAt
          updatedAt
        }
        taskOpenedUserAssessmentId
        createdAt
        updatedAt
        taskOpenedTaskId
      }
      nextToken
    }
  }
`;
export const getOnboarding = /* GraphQL */ `
  query GetOnboarding($id: ID!) {
    getOnboarding(id: $id) {
      id
      userId
      user {
        id
        email
        name
        phone
        ageBracket
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      cyberExperience
      highestEducation
      highestEducationOther
      cyberTraining
      formalTraining
      formalTrainingOther
      selfDirectedTraining {
        hours
      }
      workingIndustry
      workingIndustryOther
      createdAt
      updatedAt
    }
  }
`;
export const listOnboardings = /* GraphQL */ `
  query ListOnboardings(
    $filter: ModelOnboardingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOnboardings(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        cyberExperience
        highestEducation
        highestEducationOther
        cyberTraining
        formalTraining
        formalTrainingOther
        selfDirectedTraining {
          hours
        }
        workingIndustry
        workingIndustryOther
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getOrg = /* GraphQL */ `
  query GetOrg($id: ID!) {
    getOrg(id: $id) {
      id
      name
      alias
      websiteLink
      linkedinLink
      about
      orgRange
      orgSector
      orgSectorOther
      createdAt
      updatedAt
    }
  }
`;
export const listOrgs = /* GraphQL */ `
  query ListOrgs(
    $filter: ModelOrgFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOrgs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        members {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserConsent = /* GraphQL */ `
  query GetUserConsent($id: ID!) {
    getUserConsent(id: $id) {
      id
      userId
      type
      consentedOn
      version
      createdAt
      updatedAt
    }
  }
`;
export const listUserConsents = /* GraphQL */ `
  query ListUserConsents(
    $filter: ModelUserConsentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserConsents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        type
        consentedOn
        version
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getConsent = /* GraphQL */ `
  query GetConsent($id: ID!) {
    getConsent(id: $id) {
      id
      type
      version
      createdAt
      updatedAt
    }
  }
`;
export const listConsents = /* GraphQL */ `
  query ListConsents(
    $filter: ModelConsentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listConsents(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        type
        version
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getRole = /* GraphQL */ `
  query GetRole($id: ID!) {
    getRole(id: $id) {
      id
      name
      permissions
      createdAt
      updatedAt
    }
  }
`;
export const listRoles = /* GraphQL */ `
  query ListRoles(
    $filter: ModelRoleFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listRoles(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        permissions
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGroup = /* GraphQL */ `
  query GetGroup($id: ID!) {
    getGroup(id: $id) {
      id
      name
      description
      users {
        items {
          id
          groupId
          userId
          createdAt
          updatedAt
        }
        nextToken
      }
      createdAt
      updatedAt
    }
  }
`;
export const listGroups = /* GraphQL */ `
  query ListGroups(
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroups(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        description
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getGroupUser = /* GraphQL */ `
  query GetGroupUser($id: ID!) {
    getGroupUser(id: $id) {
      id
      groupId
      userId
      user {
        id
        email
        name
        phone
        ageBracket
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      group {
        id
        name
        description
        users {
          nextToken
        }
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listGroupUsers = /* GraphQL */ `
  query ListGroupUsers(
    $filter: ModelGroupUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroupUsers(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        groupId
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        group {
          id
          name
          description
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listAssessmentsByOrg = /* GraphQL */ `
  query ListAssessmentsByOrg(
    $orgId: String!
    $sortDirection: ModelSortDirection
    $filter: ModelAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssessmentsByOrg(
      orgId: $orgId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgId
        name
        description
        teamBased
        startDateTime
        endDateTime
        status
        hours
        minutes
        tasks {
          items {
            taskId
          }
          nextToken
        }
        preMessage
        postMessage
        createdBy
        participantEventType
        creator {
          id
          name
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listAssessmentsByParticipantEventType = /* GraphQL */ `
  query ListAssessmentsByParticipantEventType(
    $orgId: String!
    $participantEventType: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssessmentsByParticipantEventType(
      orgId: $orgId
      participantEventType: $participantEventType
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        orgId
        status
        name
        description
        teamBased
        startDateTime
        endDateTime
        hours
        minutes
        preMessage
        postMessage
        createdBy
        participantEventType
        createdAt
        updatedAt
        tasks {
          items {
            taskId
          }
        }
        users {
          items {
            startedOn
            status
            finishedOn
            userId
          }
        }
      }
      nextToken
    }
  }
`;
export const listTasksByUserId = /* GraphQL */ `
  query listTasksByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasksByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        type
        category
        difficulty
        specialtyId
        specialty {
          id
          name
        }
        userId
        status
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listUsersByAssessmentId = /* GraphQL */ `
  query ListUsersByAssessmentId(
    $userAssessmentAssessmentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsersByAssessmentId(
      userAssessmentAssessmentId: $userAssessmentAssessmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        assessment {
          id
          orgId
          name
          description
          teamBased
          startDateTime
          endDateTime
          hours
          minutes
          preMessage
          postMessage
          createdBy
          participantEventType
          createdAt
          updatedAt
        }
        userAssessmentAssessmentId
        groupId
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getUserById = /* GraphQL */ `
  query GetUserById(
    $id: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    getUserById(
      id: $id
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        name
        ageBracket
        type
        participantType
        orgId
        alias
        currentOccupation
        location
        workStatus
        jobPool
        gender
        neurodiversity
        ethnicity
        remote
        workArrangement
        relocate
        minSalary
        maxSalary
        key
        accomplishments {
          items {
            id
            name
            providerName
            startYear
            type
            userId
            yearAwarded
            endYear
            updatedAt
          }
        }
        file {
          bucket
          key
          region
        }
        org {
          id
          name
          specialThemes {
            items {
              theme {
                name
              }
            }
          }
          createdAt
          updatedAt
        }
        verifiedOnboarding
        socialMedias {
          items {
            id
            type
            url
          }
        }
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listUsersByOrgId = /* GraphQL */ `
  query ListUsersByOrgId(
    $orgId: ID!
    $email: ModelStringKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsersByOrgId(
      orgId: $orgId
      email: $email
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        type
        participantType
        orgId
        alias
        org {
          id
          name
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listOnboardingsByUserId = /* GraphQL */ `
  query ListOnboardingsByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelOnboardingFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listOnboardingsByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        cyberExperience
        highestEducation
        highestEducationOther
        cyberTraining
        formalTraining
        formalTrainingOther
        selfDirectedTraining {
          hours
        }
        workingIndustry
        workingIndustryOther
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listInvitedUsersByOrg = /* GraphQL */ `
  query ListInvitedUsersByOrg(
    $orgId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInvitedUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvitedUsersByOrg(
      orgId: $orgId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        orgId
        alias
        assessmentId
        accepted
        participantType
        permissions
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listInvitedUsersByAssessmentId = /* GraphQL */ `
  query ListInvitedUsersByAssessmentId(
    $assessmentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelInvitedUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listInvitedUsersByAssessmentId(
      assessmentId: $assessmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        email
        type
        orgId
        alias
        assessmentId
        accepted
        participantType
        permissions
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listUsersByGroupId = /* GraphQL */ `
  query ListUsersByGroupId(
    $groupId: ID!
    $userId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGroupUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUsersByGroupId(
      groupId: $groupId
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        group {
          id
          name
          description
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listGroupsByUserId = /* GraphQL */ `
  query ListGroupsByUserId(
    $userId: ID!
    $groupId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGroupUserFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroupsByUserId(
      userId: $userId
      groupId: $groupId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        groupId
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        group {
          id
          name
          description
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listAssessmentsByGroupId = /* GraphQL */ `
  query ListAssessmentsByGroupId(
    $groupId: ID!
    $assessmentId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGroupAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAssessmentsByGroupId(
      groupId: $groupId
      assessmentId: $assessmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        groupId
        group {
          id
          name
          description
          createdAt
          updatedAt
        }
        assessmentId
        assessment {
          id
          orgId
          name
          description
          teamBased
          startDateTime
          endDateTime
          hours
          minutes
          preMessage
          postMessage
          createdBy
          participantEventType
          createdAt
          updatedAt
        }
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listGroupsByAssessmenId = /* GraphQL */ `
  query ListGroupsByAssessmenId(
    $assessmentId: ID!
    $groupId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelGroupAssessmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroupsByAssessmenId(
      assessmentId: $assessmentId
      groupId: $groupId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        groupId
        group {
          id
          name
          description
          createdAt
          updatedAt
        }
        assessmentId
        assessment {
          id
          orgId
          name
          description
          teamBased
          startDateTime
          endDateTime
          hours
          minutes
          preMessage
          postMessage
          createdBy
          participantEventType
          createdAt
          updatedAt
        }
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listGroupsByOrgId = /* GraphQL */ `
  query ListGroupsByOrgId(
    $orgId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelGroupFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listGroupsByOrgId(
      orgId: $orgId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        users {
          items {
            id
            user {
              id
              alias
            }
          }
          nextToken
        }
        orgId
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listTaskActivities = /* GraphQL */ `
  query ListTaskActivities(
    $filter: ModelTaskActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskActivities(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        activity
        userId
        assessmentId
        taskId
      }
      nextToken
    }
  }
`;
export const listTaskActivitiesByAssessmentId = /* GraphQL */ `
  query ListTaskActivitiesByAssessmentId(
    $assessmentId: ID!
    $groupId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskActivityFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskActivitiesByAssessmentId(
      assessmentId: $assessmentId
      groupId: $groupId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        activity
        userId
        assessmentId
        taskId
      }
      nextToken
    }
  }
`;
export const listGroupMembersByGroupId = /* GraphQL */ `
  query ListGroupMembersByGroupId($groupId: ID!, $orgId: ID) {
    listGroupMembersByGroupId(groupId: $groupId, orgId: $orgId) {
      id
      orgId
      alias
    }
  }
`;

export const listTasksByOrgId = /* GraphQL */ `
  query ListTasksByOrgId(
    $orgId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTaskFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTasksByOrgId(
      orgId: $orgId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        type
        labId
        lab {
          name
        }
        externalId
        orgId
        org {
          id
          name
        }
        category
        difficulty
        recommendedPoints
        estimatedSolveTime
        competencies {
          items {
            area {
              areaName
            }
          }
        }
        specialtyId
        specialty {
          name
          skills {
            items {
              id
              name
            }
          }
        }
        assessments {
          nextToken
        }
        files {
          items {
            id
            name
            type
            url
            size
          }
        }
        hints {
          items {
            id
            taskId
            text
          }
        }
        userId
        user {
          id
          name
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
          org {
            id
            name
          }
        }
        modulePartId
        modulePart {
          name
          description
          courseModuleId
          type
          quizId
          duration
          expiry
          inactivityExpiry
          initialLabCount
          minLabCount
          labId
          lab {
            name
            status
          }
          url
          orderNumber
          status
          id
          createdAt
          updatedAt
          courseModulePartsId
        }
        status
        orgs {
          nextToken
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listTaskOrgs = /* GraphQL */ `
  query ListTaskOrgs(
    $filter: ModelTaskOrgFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskOrgs(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        taskId
        orgId
        task {
          id
          name
          description
          type
          externalId
          orgId
          org {
            id
            name
          }
          category
          difficulty
          competencies {
            items {
              area {
                areaName
              }
            }
          }
          specialtyId
          specialty {
            name
            skills {
              items {
                id
                name
              }
            }
          }
          hints {
            items {
              id
              taskId
              text
            }
          }
          files {
            items {
              id
              name
              type
              url
              size
            }
          }
          recommendedPoints
          estimatedSolveTime
          userId
          user {
            id
            name
            org {
              id
              name
            }
          }
          modulePartId
          modulePart {
            name
            labId
            expiry
            lab {
              name
              status
            }
          }
          status
          createdAt
          updatedAt
        }
        org {
          id
          name
          createdAt
          updatedAt
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listProductsByOrgId = /* GraphQL */ `
  query ListProductsByOrgId(
    $orgId: ID!
    $productId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelProductOrgFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listProductsByOrgId(
      orgId: $orgId
      productId: $productId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        product {
          id
          name
        }
      }
    }
  }
`;

export const listScoreboards = /* GraphQL */ `
  query ListScoreboards(
    $filter: ModelScoreboardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listScoreboards(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        userId
        user {
          alias
        }
        groupId
        group {
          name
        }
        flags
        firstBloods
        points
        successRate
        assessmentId
        updatedAt
      }
      nextToken
    }
  }
`;
export const getTaskAttemptAggregate = /* GraphQL */ `
  query GetTaskAttemptAggregate($id: ID!) {
    getTaskAttemptAggregate(id: $id) {
      id
      userId
      user {
        id
        email
        name
        phone
        ageBracket
        type
        participantType
        orgId
        alias
        org {
          id
          name
          maxInvitees
          remainingInvitees
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      groupId
      group {
        id
        name
        description
        users {
          nextToken
        }
        orgId
        createdAt
        updatedAt
      }
      assessmentId
      assessment {
        id
        orgId
        name
        description
        teamBased
        startDateTime
        endDateTime
        hours
        minutes
        multiLevel
        level
        levels {
          nextToken
        }
        tasks {
          nextToken
        }
        story
        preMessage
        postMessage
        createdBy
        participantEventType
        creator {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        hideScoreBoard
        showPostAssessmentReflection
        status
        createdAt
        updatedAt
      }
      taskId
      task {
        id
        name
        description
        externalId
        orgId
        org {
          id
          name
          maxInvitees
          remainingInvitees
          createdAt
          updatedAt
        }
        category
        difficulty
        recommendedPoints
        estimatedSolveTime
        solution
        competencies {
          nextToken
        }
        assessments {
          nextToken
        }
        answer
        files {
          nextToken
        }
        hints {
          nextToken
        }
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        modulePartId
        modulePart {
          name
          description
          courseModuleId
          type
          quizId
          duration
          expiry
          inactivityExpiry
          initialLabCount
          minLabCount
          deletionMode
          labId
          url
          orderNumber
          status
          id
          createdAt
          updatedAt
          courseModulePartsId
        }
        status
        history {
          nextToken
        }
        orgs {
          nextToken
        }
        createdAt
        updatedAt
      }
      levels
      status
      points
      userAssessmentId
      userAssessment {
        id
        userId
        user {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        assessment {
          id
          orgId
          name
          description
          teamBased
          startDateTime
          endDateTime
          hours
          minutes
          multiLevel
          level
          story
          preMessage
          postMessage
          createdBy
          participantEventType
          hideScoreBoard
          showPostAssessmentReflection
          status
          createdAt
          updatedAt
        }
        userAssessmentAssessmentId
        groupId
        status
        startedOn
        finishedOn
        createdAt
        updatedAt
      }
      attempts
      createdAt
      updatedAt
    }
  }
`;
export const listTaskAttemptAggregates = /* GraphQL */ `
  query ListTaskAttemptAggregates(
    $filter: ModelTaskAttemptAggregateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskAttemptAggregates(
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        groupId
        assessmentId
        taskId
        levels
        status
        points
        userAssessmentId
        attempts
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const getScoreboard = /* GraphQL */ `
  query GetScoreboard($id: ID!) {
    getScoreboard(id: $id) {
      id
      userId
      user {
        id
        email
        name
        phone
        ageBracket
        type
        participantType
        orgId
        alias
        org {
          id
          name
          maxInvitees
          remainingInvitees
          createdAt
          updatedAt
        }
        verifiedOnboarding
        status
        permissions
        groups {
          nextToken
        }
        tasks {
          nextToken
        }
        theme
        createdAt
        updatedAt
      }
      groupId
      group {
        id
        name
        description
        users {
          nextToken
        }
        orgId
        createdAt
        updatedAt
      }
      flags
      firstBloods
      points
      successRate
      assessmentId
      assessment {
        id
        orgId
        name
        description
        teamBased
        startDateTime
        endDateTime
        hours
        minutes
        multiLevel
        level
        levels {
          nextToken
        }
        tasks {
          nextToken
        }
        story
        preMessage
        postMessage
        createdBy
        participantEventType
        creator {
          id
          email
          name
          phone
          ageBracket
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        groups {
          nextToken
        }
        hideScoreBoard
        showPostAssessmentReflection
        status
        createdAt
        updatedAt
      }
      createdAt
      updatedAt
    }
  }
`;
export const listModuleParts = /* GraphQL */ `
  query ListModuleParts(
    $filter: ModelModulePartFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listModuleParts(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        name
        description
        courseModuleId
        type
        duration
        expiry
        inactivityExpiry
        initialLabCount
        minLabCount
        deletionMode
        labId
        lab {
          id
          name
        }
        orderNumber
        status
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listCoursesByOrgId = /* GraphQL */ `
  query ListCoursesByOrgId(
    $orgId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelCourseFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listCoursesByOrgId(
      orgId: $orgId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        courseLessons {
          items {
            id
          }
        }
        orgId
        name
        description
        status
        availability
        categoryId
        available
        image {
          bucket
          key
          region
        }
        category {
          name
          id
          createdAt
          updatedAt
        }
        ownerId
        user {
          id
          name
          type
          participantType
          orgId
          alias
          verifiedOnboarding
          status
          permissions
          theme
          createdAt
          updatedAt
        }
        courseUsers {
          items {
            id
            userId
            messages(filter: { seen: { eq: FALSE } }) {
              items {
                id
                messages {
                  content
                  type
                }
                userCourseId
                sender {
                  id
                  type
                  name
                }
                createdAt
              }
            }
          }
        }
        coursegroups {
          items {
            id
            groupId
          }
        }
        id
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;
export const listTeamsByUserId = /* GraphQL */ `
  query ListTeamsByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeamsByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        description
        alias
        key
        userId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const getTeam = /* GraphQL */ `
  query GetTeam($id: ID!) {
    getTeam(id: $id) {
      id
      name
      key
      members {
        items {
          id
          role
          userId
          User {
            name
            alias
          }
          createdAt
        }
      }
    }
  }
`;
export const listTeams = /* GraphQL */ `
  query ListTeams(
    $filter: ModelTeamFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeams(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        key
        members {
          items {
            id
            email
            role
            userId
            User {
              name
              alias
            }
            createdAt
          }
        }
        events {
          items {
            eventId
            event {
              name
              startDateTime
              status
              endDateTime
              availabilityType
            }
          }
        }
      }
      nextToken
    }
  }
`;
export const listUserAssessmentsByTeamId = /* GraphQL */ `
  query ListUserAssessmentsByTeamId(
    $filter: ModelUserAssessmentFilterInput
    $limit: Int
    $nextToken: String
    $teamId: ID!
  ) {
    listUserAssessmentsByTeamId(
      teamId: $teamId
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userAssessmentAssessmentId
        userId
        status
      }
      nextToken
    }
  }
`;
export const queryTemplatesByStatus = /* GraphQL */ `
  query QueryTemplatesByStatus(
    $status: TEMPLATE_STATUS!
    $sortDirection: ModelSortDirection
    $filter: ModelTemplateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    queryTemplatesByStatus(
      status: $status
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        name
        status
        tasks {
          items {
            taskId
            task {
              id
              name
              type
              description
              summary
              externalId
              orgId
              org {
                name
              }
              category
              difficulty
              specialtyId
              specialty {
                id
                name
                description
                createdAt
                updatedAt
              }
              skills {
                items {
                  id
                  skillId
                  skill {
                    id
                    name
                  }
                  techniqueTags {
                    items {
                      techniqueTag {
                        name
                      }
                    }
                  }
                }
                nextToken
              }
              technologyTags {
                items {
                  technologyTagID
                  technologyTag {
                    id
                    name
                  }
                }
              }
              attempts(filter: { success: { eq: true } }) {
                items {
                  id
                }
              }
              competencies {
                items {
                  workRole {
                    area {
                      areaName
                    }
                  }
                }
              }
              hints {
                items {
                  id
                  taskId
                  text
                }
              }
              files {
                items {
                  id
                  name
                  type
                  url
                  size
                }
              }
              userId
              user {
                id
                name
                type
                orgId
              }
              recommendedPoints
              estimatedSolveTime
              userId
              modulePartId
              labId
              lab {
                id
                name
                vms {
                  items {
                    id
                  }
                }
                createdAt
                updatedAt
              }
              status
              createdAt
              updatedAt
            }
          }
          nextToken
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listTeamsByUser = /* GraphQL */ `
  query ListTeamsByUser(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelTeamMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTeamsByUser(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        team {
          id
          name
          key
          private
          members {
            items {
              id
              role
              userId
              User {
                name
                alias
              }
              createdAt
            }
          }
          events(filter: { status: { ne: REMOVED } }) {
            items {
              eventId
              event {
                name
                startDateTime
                status
                endDateTime
                availabilityType
                teams {
                  items {
                    teamId
                    team {
                      name
                      members {
                        items {
                          userId
                        }
                      }
                    }
                  }
                }
              }
              status
            }
          }
        }
      }
      nextToken
    }
  }
`;

export const listSharedAssessmentBySharedOrgId = /* GraphQL */ `
  query ListSharedAssessmentBySharedOrgId(
    $sharedOrgId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAssessmentSharedFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSharedAssessmentBySharedOrgId(
      sharedOrgId: $sharedOrgId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        assessmentId
        assessment {
          id
          orgId
          name
          description
          teamBased
          startDateTime
          endDateTime
          hours
          minutes
          multiLevel
          level
          story
          preMessage
          postMessage
          createdBy
          participantEventType
          hideScoreBoard
          guided
          showPostAssessmentReflection
          status
          availabilityType
          enableJumpbox
          enableVPN
          modulePartId
          videoUrl
          maxTeamSize
          createdAt
          updatedAt
          __typename
        }
        orgId
        org {
          id
          name
          alias
          maxInvitees
          remainingInvitees
          themeId
          pricingTier
          websiteLink
          linkedinLink
          about
          orgRange
          orgSector
          orgSectorOther
          createdAt
          updatedAt
          __typename
        }
        sharedOrgId
        sharedOrg {
          id
          name
          alias
          maxInvitees
          remainingInvitees
          themeId
          pricingTier
          websiteLink
          linkedinLink
          about
          orgRange
          orgSector
          orgSectorOther
          createdAt
          updatedAt
          __typename
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const listScoreboardsByTeamId = /* GraphQL */ `
  query ListScoreboardsByTeamId(
    $teamId: ID!
    $assessmentId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelScoreboardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listScoreboardsByTeamId(
      teamId: $teamId
      assessmentId: $assessmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        groupId
        teamId
        flags
        firstBloods
        points
        successRate
        assessmentId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listSkills = /* GraphQL */ `
  query ListSkills(
    $filter: ModelSkillFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSkills(filter: $filter, limit: $limit, nextToken: $nextToken) {
      items {
        id
        name
        alias
        specialtyId
        specialty {
          name
        }
      }
      nextToken
    }
  }
`;
export const listTaskAttemptAggregatesByUserId = /* GraphQL */ `
  query ListTaskAttemptAggregatesByUserId(
    $userId: ID!
    $assessmentId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelTaskAttemptAggregateFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listTaskAttemptAggregatesByUserId(
      userId: $userId
      assessmentId: $assessmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        taskId
        status
        attempts
        task {
          id
          specialtyId
          skills {
            items {
              skillId
              skill {
                id
                name
                alias
              }
            }
          }
          difficulty
        }
        createdAt
        updatedAt
      }
      nextToken
    }
  }
`;

export const listUserScoreboardsByUserId = /* GraphQL */ `
  query ListUserScoreboardsByUserId(
    $userId: ID!
    $assessmentId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserScoreboardFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserScoreboardsByUserId(
      userId: $userId
      assessmentId: $assessmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        teamId
        flags
        firstBloods
        points
        successScore
        speed
        efficiency
        score
        assessmentId
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const listaccomplishmentByUserId = /* GraphQL */ `
  query ListaccomplishmentByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelAccomplishmentFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listaccomplishmentByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        type
        name
        providerName
        startYear
        endYear
        yearAwarded
        userId
        id
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

export const listAffliationsByUserId = /* GraphQL */ `
  query ListAffliationsByUserId(
    $userId: ID!
    $orgId: ModelIDKeyConditionInput
    $sortDirection: ModelSortDirection
    $filter: ModelUserOrgAffliationFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listAffliationsByUserId(
      userId: $userId
      orgId: $orgId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        orgId
        status
        createdAt
        updatedAt
        org {
          alias
          name
          pricingTier
        }
      }
      nextToken
      __typename
    }
  }
`;
export const listUserMetricsByUserId = /* GraphQL */ `
  query ListUserMetricsByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelUserMetricFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listUserMetricsByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        userId
        orgId
        successScore
        lastSuccessScore
        speed
        lastSpeed
        efficiency
        lastEfficiency
        score
        lastScore
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;
export const listSquadMembersByUserId = /* GraphQL */ `
  query ListSquadMembersByUserId(
    $userId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelSquadMemberFilterInput
    $limit: Int
    $nextToken: String
  ) {
    listSquadMembersByUserId(
      userId: $userId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        squadId
        role
        squad {
          name
        }
      }
      nextToken
    }
  }
`;
