import React, { useState } from 'react';
import {
  Box,
  FormGroup,
  FormControlLabel,
  Switch,
  Slider,
} from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import {
  FdCard,
  FdTypography,
  FdRadioGroup,
  FdDelayed,
} from '@fifthdomain/fe-shared';
import ProfileView from './ProfileView';
import ProfileHeading from './ProfileHeading';
import {
  WORKING_ARRANGEMENT,
  PROFILE_DEFAULT_VALUES,
  PROFILE_VIEW_VALUES,
} from '../../constants';

const Logistics = () => {
  const [editMode, setEditMode] = useState(false);
  const { control, setValue } = useFormContext();

  return (
    <FdCard
      heading={
        <ProfileHeading
          heading="Logistics"
          editMode={editMode}
          setEditMode={setEditMode}
        />
      }
      data-cy="Logistics"
    >
      {editMode ? (
        <Box>
          <Controller
            control={control}
            name="workArrangement"
            render={({
              field: { ref, value: fieldValue, ...rest },
              fieldState: { error },
            }) => (
              <FdDelayed>
                <Box mt={1} mb={2} ml={1}>
                  <FdRadioGroup
                    id="workArrangement"
                    label="Preferred Working Arrangement"
                    options={Object.values(WORKING_ARRANGEMENT)}
                    defaultValue={fieldValue}
                    fullWidth
                    error={error && error.message}
                    data-cy="workArrangement"
                    {...rest}
                    inputRef={ref}
                  />
                </Box>
              </FdDelayed>
            )}
          />

          <Controller
            control={control}
            name="relocate"
            render={({
              field: { ref, value: fieldValue, ...rest },
              fieldState: { error },
            }) => (
              <FormGroup>
                <FdTypography variant="subtitle1">
                  Willingness to Relocate
                </FdTypography>
                <FormControlLabel
                  control={
                    <Switch
                      id="relocate"
                      checked={fieldValue}
                      value={fieldValue?.value}
                      error={error && error.message}
                      data-cy="relocate"
                      {...rest}
                      onChange={(e) => {
                        setValue('relocate', e.target.checked);
                      }}
                      inputRef={ref}
                    />
                  }
                />
              </FormGroup>
            )}
          />

          <Controller
            control={control}
            name="salaryRange"
            render={({ field: { ...rest }, fieldState: { error } }) => (
              <Box mt={2}>
                <FdTypography variant="subtitle1">
                  Salary Expectations
                </FdTypography>
                <Box m={1}>
                  <Slider
                    min={0}
                    max={500}
                    step={50}
                    marks={[
                      {
                        value: 0,
                        label: '0',
                      },
                      {
                        value: 500,
                        label: '500k+',
                      },
                    ]}
                    value={rest?.value}
                    onChange={(event, newValue) => {
                      setValue('salaryRange', newValue);
                    }}
                    valueLabelDisplay="auto"
                    disableSwap
                  />
                  {error && (
                    <FdTypography
                      variant="captiontext1"
                      style={{
                        color: '#C62828',
                      }}
                    >
                      {error?.message}
                    </FdTypography>
                  )}
                </Box>
              </Box>
            )}
          />
        </Box>
      ) : (
        <ProfileView
          defaultValue={PROFILE_DEFAULT_VALUES[5]}
          title={PROFILE_VIEW_VALUES?.slice(7)}
        />
      )}
    </FdCard>
  );
};

export default Logistics;
