import React, { useState } from 'react';
import debounce from 'lodash/debounce';
import { gql } from '@apollo/client';
import { Box } from '@mui/material';
import SearchIcon from '@mui/icons-material/Search';
import {
  BasePage,
  FdTypography,
  FdCard,
  FdTextField,
  FdSkeleton,
  FdButton,
  useQueryRecursive,
  useSnapshot,
  globalStore,
  warningToastMessage,
  FdPageHeading,
} from '@fifthdomain/fe-shared';
import { FdBreadcrumbHeader } from '@fifthdomain/sidebar';
import TeamCards from '../components/ParticipantTeams/TeamCards';
import CreateTeamModal from '../components/ParticipantTeams/CreateTeamModal';
import TeamsImage from '../shared/images/teams.svg';
import { listTeamsByUser } from '../graphql/queries';

const TeamsLandingPage = () => {
  const [openCreateTeam, setOpenCreateTeam] = useState(false);
  const [searchText, setSearchText] = useState('');
  const [teamsDisplay, setTeamsDisplay] = useState([]);
  const globalSnap = useSnapshot(globalStore);
  const {
    data: listTeamsByUserData,
    loading: listTeamsLoading,
    refetch: refetchTeams,
  } = useQueryRecursive(gql(listTeamsByUser), {
    variables: {
      userId: globalSnap.userId,
      limit: 1000,
    },
    staleTime: { minutes: 0 },
    onCompleted: (_data) =>
      setTeamsDisplay(
        _data?.listTeamsByUser?.items.filter((t) => t.team) || [],
      ),
  });

  const teams =
    listTeamsByUserData?.listTeamsByUser?.items.filter((t) => t.team) || [];

  const onCancelModal = ({ refetch = false, noToast = true }) => {
    setOpenCreateTeam(false);
    if (!noToast) {
      warningToastMessage('Team creation cancelled');
    }
    if (refetch) {
      refetchTeams();
    }
  };

  const handleSearch = debounce((queryText) => {
    if (queryText) {
      const filteredData = teams?.filter((t) =>
        t?.team?.name?.toLowerCase().includes(queryText?.toLowerCase()),
      );
      setTeamsDisplay(filteredData);
    } else {
      setTeamsDisplay(teams); // clear the search results when queryText is empty
    }
  }, 300);

  const onInputChange = (event) => {
    const queryTextValue = event.target.value;
    setSearchText(queryTextValue);
    const queryText = queryTextValue.trim();
    handleSearch(queryText);
  };

  const noTeamsMessage =
    teams.length === 0
      ? 'When you are invited to a team, it will populate here.'
      : 'Teams not found. Refine your search and try again.';

  return (
    <Box>
      <FdBreadcrumbHeader page={{ name: 'My Teams', type: 'TEAM' }} />
      <BasePage>
        <FdCard variant="outlined">
          <Box className="flex items-center justify-between">
            <Box>
              <FdPageHeading type="TEAM" variant="h4">
                My Teams
              </FdPageHeading>
              <FdTypography
                variant="body2"
                color="secondary"
                style={{ marginTop: '0.5rem' }}
              >
                From this page, you can view and manage the teams you are
                currently part of, and create new teams.
              </FdTypography>
            </Box>
            <Box className="flex flex-wrap gap-4">
              <FdButton
                disableTouchRipple
                onClick={() => setOpenCreateTeam(true)}
              >
                Create New team
              </FdButton>
            </Box>
          </Box>
        </FdCard>
        <FdCard variant="outlined" style={{ marginTop: '1rem' }}>
          <FdSkeleton loading={listTeamsLoading} height="792px">
            <Box className="flex flex-col w-full">
              {teams.length > 0 && (
                <Box className="self-end mb-5">
                  <FdTextField
                    id="searchText"
                    value={searchText}
                    placeholder="Search"
                    endAdornment={<SearchIcon />}
                    onChange={onInputChange}
                  />
                </Box>
              )}
              {teamsDisplay?.length === 0 ? (
                <Box
                  height="620px"
                  className="flex flex-col justify-center items-center w-full"
                >
                  <Box>
                    <img src={TeamsImage} alt="no-content-data" />
                  </Box>
                  <FdTypography variant="h4">{noTeamsMessage}</FdTypography>
                </Box>
              ) : (
                <TeamCards teams={teamsDisplay} />
              )}
            </Box>
          </FdSkeleton>
        </FdCard>
        <CreateTeamModal open={openCreateTeam} onCancel={onCancelModal} />
      </BasePage>
    </Box>
  );
};
export default TeamsLandingPage;
