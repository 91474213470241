import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import {
  FdTable,
  FdTypography,
  FdAvatarGroup,
  getAvatarName,
  getAvatarColor,
} from '@fifthdomain/fe-shared';
import { CrownGoldIcon } from '../Icons/CrownGoldIcon';
import { CrownSilverIcon } from '../Icons/CrownSilverIcon';
import { CrownBronzeIcon } from '../Icons/CrownBronzeIcon';
import TeamAvatar from './TeamAvatar';

const TopTeams = ({ rows }) => {
  const iconMap = {
    1: <CrownGoldIcon />,
    2: <CrownSilverIcon />,
    3: <CrownBronzeIcon />,
  };

  const columns = [
    {
      field: 'rank',
      width: 100,
      headerName: 'Rank',
      valueGetter: (params) => params.value,
      renderCell: (params) => {
        const Icon = iconMap[params.value];

        return (
          <Box className="flex items-center gap-x-3">
            {params.row.rank}
            <span style={{ position: 'relative', left: '2px', top: '-1px' }}>
              {Icon}
            </span>
          </Box>
        );
      },
    },
    {
      field: 'teamName',
      width: 450,
      headerName: 'Team Name',
      valueGetter: (params) => params.value?.name,
      renderCell: (params) => {
        return (
          <Box className="flex items-center gap-x-3">
            <TeamAvatar size="small" team={params.row.team} />
            <FdTypography>{params.row.team.name}</FdTypography>
          </Box>
        );
      },
    },
    {
      field: 'teamMembers',
      width: 200,
      headerName: 'Team Members',
      unSort: true,
      renderCell: (params) => {
        const memberAvatars =
          params?.row?.teamMembers?.map((m) => {
            const avatarName = m?.User?.alias || '-';
            return {
              content: m?.User ? getAvatarName(avatarName) : <PersonIcon />,
              color: m?.User ? getAvatarColor(avatarName) : 'lightgrey',
            };
          }) || [];
        return (
          <Box className="flex items-center gap-x-3">
            <FdAvatarGroup
              avatars={memberAvatars}
              size="xsmall"
              max={5}
              data-cy="avatar-group"
              spacing="small"
            />
          </Box>
        );
      },
    },
    {
      field: 'points',
      width: 200,
      headerName: 'Points',
    },
    { field: 'firstBloods', width: 150, headerName: 'First Solves' },
    {
      field: 'successRate',
      width: 200,
      headerName: 'Success Rate',
      valueFormatter: (params) => `${params.value}%`,
    },
    { field: 'competitionsCount', width: 150, headerName: 'Competitions' },
  ];

  return (
    <Box height="490px">
      <FdTable
        toolbarSettings={{
          title: '',
          filterButton: true,
          searchBox: true,
        }}
        rows={rows || []}
        columns={columns}
        tablePageSize={10}
        gridId="top-individuals"
      />
    </Box>
  );
};

TopTeams.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
};

export default TopTeams;
