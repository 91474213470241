import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { useForm, Controller, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import { gql, useMutation } from '@apollo/client';
import { Box } from '@mui/material';
import {
  FdTypography,
  FdModal,
  FdTextField,
  FdAlert,
  useSnapshot,
  globalStore,
} from '@fifthdomain/fe-shared';
import {
  validationSchema,
  initialValues,
} from '../../validation-schemas/Teams';
import FdImageUpload from './FdImageUpload';
import InviteTeamMembers from './InviteTeamMembers';
import { createTeam, createTeamMember } from '../../graphql/mutations';
import {
  errorToastMessage,
  successToastMessage,
} from '../../shared/utils/toast';
import { uploadFileToS3 } from '../../shared/utils/fileUploadAWS';

const pages = [
  {
    title: 'Create a New Team',
    description:
      'Create a team that others on the platform can join. You can edit details later by viewing this team on the My Teams page.',
    confirmText: 'Create and Next',
  },
  {
    title: 'Congratulations! Your team was created.',
    description:
      'You can invite team members below now, or close this window if you wish to do this later. ',
    confirmText: 'Close',
  },
];

const CreateTeamModal = ({ open, onCancel }) => {
  const [currentPage, setCurrentPage] = useState(0);
  const globalSnap = useSnapshot(globalStore);
  const reactHookFormMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });
  const [createTeamMutation, { loading: createTeamLoading }] = useMutation(
    gql(createTeam),
  );
  const [createTeamMemberMutation, { loading: createTeamMemberLoading }] =
    useMutation(gql(createTeamMember));

  const { reset, control, trigger, setValue, getValues, watch } =
    reactHookFormMethods;
  const watchAvatar = watch('avatar');

  useEffect(() => {
    reset();
    setCurrentPage(0);
  }, [open, reset]);

  const page = pages[currentPage];

  return (
    <Box>
      <FdModal
        size="md"
        title={page?.title}
        description={page?.description}
        confirm={page?.confirmText}
        dismiss="CANCEL"
        open={open}
        confirmLoading={createTeamLoading || createTeamMemberLoading}
        onConfirm={async () => {
          switch (currentPage) {
            case 0: {
              const valid = await trigger(['name', 'error']);
              if (valid) {
                const { name, avatar } = getValues();
                const avatarKey = `${name}/${avatar?.name}`
                  .replace(/\s/g, '-')
                  .toLowerCase();
                // create team
                createTeamMutation({
                  variables: {
                    input: {
                      name,
                      creatorId: globalSnap.userId,
                      ...(avatar && { key: avatarKey }),
                    },
                  },
                  onCompleted: (_data) => {
                    const teamId = _data?.createTeam?.id;
                    // add creator as the team's team manager
                    createTeamMemberMutation({
                      variables: {
                        input: {
                          userId: globalSnap.userId,
                          orgId: globalSnap.orgId,
                          teamId,
                          role: 'TEAM_MANAGER',
                          email: globalSnap.userEmail,
                        },
                      },
                    });
                    setValue('id', teamId);
                    // if avatar present then upload
                    if (avatar) {
                      uploadFileToS3({
                        key: `/team-avatars/${avatarKey}`,
                        file: avatar,
                        level: 'public',
                      });
                    }
                    setCurrentPage(1);
                    successToastMessage('Your team was created!');
                  },
                  onError: ({ graphQLErrors }) => {
                    if (graphQLErrors) {
                      errorToastMessage(graphQLErrors[0]?.message);
                    }
                  },
                });
              }
              break;
            }
            case 1: {
              reset();
              onCancel({ refetch: true, noToast: true });
              break;
            }
            default:
              break;
          }
        }}
        onDismiss={() => {
          reset();
          onCancel({ refetch: false, toast: true });
        }}
        showDismiss={currentPage === 0}
      >
        <FormProvider {...reactHookFormMethods}>
          <form>
            {
              {
                0: (
                  <Box>
                    <Controller
                      control={control}
                      name="name"
                      render={({
                        field: { ref, ...rest },
                        fieldState: { error },
                      }) => (
                        <Box mt={1} mb={2}>
                          <FdTextField
                            id="name"
                            label="Team Name"
                            required
                            fullWidth
                            error={error}
                            placeholder="Enter Team Name"
                            helperText={
                              error
                                ? error.message
                                : 'Enter a name for your team.'
                            }
                            {...rest}
                            inputRef={ref}
                          />
                        </Box>
                      )}
                    />
                    <Box>
                      <Box display="flex" alignItems="center">
                        <FdTypography variant="subtitle1">
                          Team Avatar
                        </FdTypography>
                        <FdTypography
                          variant="captiontext1"
                          color="secondary"
                          style={{ paddingLeft: '0.5rem' }}
                        >
                          optional
                        </FdTypography>
                      </Box>
                      <FdTypography variant="captiontext1" className="py-1">
                        Upload a team avatar image file by clicking the Upload
                        Image button below. Files greater than 2MB cannot be
                        supported. JPEG, JPG, and PNG image file types are
                        accepted. Avatar framing will be optimal for files with
                        a square size ratio.
                      </FdTypography>
                      <Box mt={1} mb={3}>
                        <FdImageUpload
                          onDrop={(files) => {
                            setValue('avatar', files[0]);
                          }}
                          onDropRejected={(files) => {
                            setValue('avatar', files[0]?.file);
                            setValue('error', true);
                          }}
                          fileSelected={watchAvatar}
                          onDelete={() => {
                            setValue('avatar', undefined);
                            setValue('error', false);
                          }}
                        />
                      </Box>
                    </Box>
                    <FdAlert
                      alertTitle="You will be a Team Manager for this team."
                      message="As the creator of this team, you will automatically be assigned a Team Manager role. You can invite more team members by viewing this team after you finish creating it."
                      variant="info"
                    />
                  </Box>
                ),
                1: (
                  <Box>
                    <InviteTeamMembers />
                  </Box>
                ),
              }[currentPage]
            }
          </form>
        </FormProvider>
      </FdModal>
    </Box>
  );
};

CreateTeamModal.propTypes = {
  open: PropTypes.func.isRequired,
  onCancel: PropTypes.func.isRequired,
};

export default CreateTeamModal;
