import React from 'react';
import { Box, Divider } from '@mui/material';
import { FdCard } from '@fifthdomain/fe-shared';
import PropTypes from 'prop-types';
import SummaryTitle from './SummaryTitle';

const Summary = ({
  data,
  titleVariant,
  subtitleVariant,
  justifyContent,
  loading,
}) => {
  const dividerIndex = data.length - 1;

  return (
    <FdCard elevation={0}>
      <Box
        display="flex"
        width="100%"
        alignItems="center"
        justifyContent="space-around"
      >
        {data?.map((d, idx) => (
          <>
            <SummaryTitle
              data={d}
              titleVariant={titleVariant}
              subtitleVariant={subtitleVariant}
              justifyContent={justifyContent}
              loading={loading}
            />
            {idx < dividerIndex && <Divider orientation="vertical" flexItem />}
          </>
        ))}
      </Box>
    </FdCard>
  );
};

Summary.propTypes = {
  data: PropTypes.arrayOf(
    PropTypes.shape({
      value: PropTypes.oneOfType([
        PropTypes.string,
        PropTypes.number,
        PropTypes.node,
      ]),
      description: PropTypes.string,
    }).isRequired,
  ).isRequired,
  titleVariant: PropTypes.string,
  subtitleVariant: PropTypes.string,
  justifyContent: PropTypes.string,
  loading: PropTypes.bool,
};

Summary.defaultProps = {
  titleVariant: 'h4',
  subtitleVariant: 'subtitle1',
  justifyContent: 'center',
  loading: false,
};

export default Summary;
