import React from 'react';
import PropTypes from 'prop-types';
import { Box, Card, CardContent, useTheme } from '@mui/material';
import PersonIcon from '@mui/icons-material/Person';
import {
  FdTypography,
  FdAvatarGroup,
  FdButton,
  useSnapshot,
  globalStore,
  getAvatarColor,
  getAvatarName,
} from '@fifthdomain/fe-shared';
import TeamAvatar from './TeamAvatar';
import { TEAM_ROLES } from '../../constants';
import RoleChip from './RoleChip';

const TeamCard = ({ team, onViewTeam }) => {
  const globalSnap = useSnapshot(globalStore);
  const theme = useTheme();
  const { name, members } = team;
  const memberCount = members?.items.length || 0;
  const role =
    members?.items.find((m) => m.userId === globalSnap.userId)?.role ||
    TEAM_ROLES.GENERAL_MEMBER;
  const memberAvatars = members?.items
    ?.sort((a, b) => new Date(a.createdAt) - new Date(b.createdAt))
    .map((m) => {
      const avatarName = m?.User?.alias || '-';
      return {
        content: m?.User ? getAvatarName(avatarName) : <PersonIcon />,
        color: m?.User ? getAvatarColor(avatarName) : 'lightgrey',
      };
    });

  return (
    <Card
      style={{
        minHeight: '384px',
        width: '302px',
        border: `1px solid ${theme.palette.table.border}`,
      }}
      variant="outlined"
      key={name}
    >
      <CardContent className="flex flex-col items-center justify-evenly h-full">
        <TeamAvatar size="large" team={team} />
        <FdTypography variant="h3" className="break-words text-center">
          {name}
        </FdTypography>
        <FdTypography variant="body1" color="secondary" className="py-3">
          {`${memberCount} member${memberCount !== 1 ? 's' : ''}`}
        </FdTypography>
        <FdAvatarGroup
          avatars={memberAvatars}
          size="small"
          max={5}
          data-cy="avatar-group"
          spacing="small"
        />
        <Box className="flex items-center pt-4">
          <FdTypography variant="captiontext1">My Role Type:</FdTypography>
          <RoleChip role={role} />
        </Box>
        <Box pt={4}>
          <FdButton
            onClick={() => onViewTeam({ ...team, currentUserRole: role })}
          >
            View
          </FdButton>
        </Box>
      </CardContent>
    </Card>
  );
};

TeamCard.propTypes = {
  team: PropTypes.shape({
    name: PropTypes.string,
    members: PropTypes.shape({
      items: PropTypes.arrayOf(
        PropTypes.shape({
          role: PropTypes.string,
          userId: PropTypes.string,
        }),
      ),
    }),
  }).isRequired,
  onViewTeam: PropTypes.func.isRequired,
};

export default TeamCard;
