import React from 'react';
import PropTypes from 'prop-types';
import { Box, colors } from '@mui/material';
import LinkIcon from '@mui/icons-material/Link';
import LinkOffIcon from '@mui/icons-material/LinkOff';
import { FdTable, FdTypography } from '@fifthdomain/fe-shared';
import { CrownGoldIcon } from '../Icons/CrownGoldIcon';
import { CrownSilverIcon } from '../Icons/CrownSilverIcon';
import { CrownBronzeIcon } from '../Icons/CrownBronzeIcon';
import TeamAvatar from './TeamAvatar';

const TopIndividuals = ({ rows }) => {
  const iconMap = {
    1: <CrownGoldIcon />,
    2: <CrownSilverIcon />,
    3: <CrownBronzeIcon />,
  };

  const columns = [
    {
      field: 'rank',
      width: 100,
      headerName: 'Rank',
      valueGetter: (params) => params.value,
      renderCell: (params) => {
        const Icon = iconMap[params.value];

        return (
          <Box className="flex items-center gap-x-3">
            {params.row.rank}
            <span style={{ position: 'relative', left: '2px', top: '-1px' }}>
              {Icon}
            </span>
          </Box>
        );
      },
    },
    {
      field: 'alias',
      width: 450,
      headerName: 'User Alias',
      valueGetter: (params) => params.value,
      renderCell: (params) => {
        return (
          <Box className="flex items-center gap-x-3">
            <TeamAvatar size="small" team={{ name: params.row.alias }} />
            <FdTypography>{params.row.alias}</FdTypography>
          </Box>
        );
      },
    },
    {
      field: 'affiliationStatus',
      width: 200,
      headerName: 'Affiliation Status',
      valueGetter: (params) => params.value,
      renderCell: (params) => {
        const isAffiliated = params.row.affiliationStatus === 'Affiliated';
        return (
          <Box
            style={{
              backgroundColor: isAffiliated
                ? colors.green[100]
                : colors.blue[100],
              color: '#000',
            }}
            className="flex items-center gap-x-3 rounded-2xl px-2"
          >
            {isAffiliated ? <LinkIcon /> : <LinkOffIcon />}
            <FdTypography variant="captiontext1" color="black">
              {params.row.affiliationStatus}
            </FdTypography>
          </Box>
        );
      },
    },
    {
      field: 'points',
      width: 200,
      headerName: 'Points',
    },
    { field: 'firstBloods', width: 150, headerName: 'First Solves' },
    {
      field: 'successRate',
      width: 200,
      headerName: 'Success Rate',
      valueFormatter: (params) => `${params.value}%`,
    },
    { field: 'competitionsCount', width: 150, headerName: 'Competitions' },
  ];

  return (
    <Box height="490px">
      <FdTable
        toolbarSettings={{
          title: '',
          filterButton: true,
          searchBox: true,
        }}
        rows={rows || []}
        columns={columns}
        tablePageSize={10}
        gridId="top-individuals"
      />
    </Box>
  );
};

TopIndividuals.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
};

export default TopIndividuals;
