import React from 'react';
import { gql, useSubscription, useQuery } from '@apollo/client';
import { Box, useTheme } from '@mui/material';
import AssignmentIcon from '@mui/icons-material/Assignment';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import WbIncandescentIcon from '@mui/icons-material/WbIncandescent';
import {
  BasePage,
  FdTypography,
  FdLoadingSpinner,
  FdCard,
  useQueryRecursive,
  useSnapshot,
  globalStore,
  FdTab,
  FdPageHeading,
} from '@fifthdomain/fe-shared';
import { FdBreadcrumbHeader } from '@fifthdomain/sidebar';
import * as singleSpa from 'single-spa';
import {
  differenceInMilliseconds,
  differenceInMinutes,
  hoursToMinutes,
} from 'date-fns';
import { listUserAssessmentsLandingPage } from '../queries/customQueries';
import { onUpdateAssessment } from '../graphql/subscriptions';
import {
  listCourseUsers,
  getSystemTime,
  listAssessmentsByParticipantEventType,
} from '../graphql/queries';
import { getCompetitionStatus } from '../shared/utils/getParticipantStatus';
import { productStyle } from '../shared/utils/layout';
import {
  getDateTimeZoneFormatted,
  formatMinutes,
  sortedByDate,
  getDuration,
  isDateInRange,
} from '../shared/utils/dateUtils';
import { getRandomImage } from '../shared/utils/images';
import { CONTENT_IMAGES } from '../constants';
import AssignedContentGrid from '../components/Contents/AssignedContentGrid';
import TrainingSvg from '../components/Training/TrainingSvg';
import { getCourseProgressStatus } from '../shared/utils/taskUtils';

const MyEvents = () => {
  const theme = useTheme();
  const globalSnap = useSnapshot(globalStore);
  const {
    data: assessmentsData,
    loading: assessmentsLoading,
    refetch: refetchAssessmentsData,
  } = useQueryRecursive(gql(listUserAssessmentsLandingPage), {
    variables: {
      userId: globalSnap.userId,
      limit: 1000,
    },
    staleTime: { seconds: 0 },
  });

  const { data: fdTrainingsData, loading: fdTrainingsLoading } =
    useQueryRecursive(gql(listAssessmentsByParticipantEventType), {
      variables: {
        orgId: globalSnap.orgId,
        participantEventType: {
          eq: 'FDTRAINING',
        },
        limit: 1000,
      },
      staleTime: { seconds: 0 },
    });

  const { data: listCourseUsersData, loading: listCourseUsersLoading } =
    useQueryRecursive(gql(listCourseUsers), {
      variables: {
        filter: {
          userId: { eq: globalSnap.userId },
        },
        status: {
          ne: 'REMOVED',
        },
        limit: 1000,
      },
      staleTime: { seconds: 0 },
    });

  const { data: serverTime, loading: serverTimeLoading } = useQuery(
    gql(getSystemTime),
    {
      fetchPolicy: 'network-only',
    },
  );

  useSubscription(gql(onUpdateAssessment), {
    onData: (_data) => {
      refetchAssessmentsData();
    },
  });
  // get expired status for started/non-started ones as per today
  const getCompletedStatus = (_status, _endDateTime, contentType) => {
    const isNotExpired =
      differenceInMilliseconds(
        new Date(_endDateTime),
        new Date(serverTime?.getSystemTime),
      ) > 0;
    return isNotExpired
      ? _status
      : contentType === 'competition'
      ? 'ENDED'
      : 'NOT_COMPLETED';
  };

  const timeStamp = (_value) => {
    const {
      status,
      finishedOn,
      startedOn,
      contentType,
      assessment: { startDateTime, endDateTime, hours = 0, minutes = 0 },
    } = _value;

    let subHeading;
    switch (status) {
      case 'STARTED': {
        subHeading = endDateTime
          ? `Time Remaining: ${
              ['assessment'].includes(contentType)
                ? // Assessment
                  formatMinutes(
                    hoursToMinutes(hours) +
                      minutes -
                      differenceInMinutes(
                        new Date(serverTime.getSystemTime),
                        new Date(startedOn),
                      ),
                  )
                : // Competition
                  formatMinutes(
                    differenceInMinutes(
                      new Date(endDateTime),
                      new Date(serverTime.getSystemTime),
                    ),
                  )
            } `
          : 'End TBD by Admin';
        break;
      }
      case 'NOT_COMPLETED':
        subHeading = `Expired: ${getDateTimeZoneFormatted(endDateTime)}`;
        break;
      case 'NOT_STARTED':
        subHeading = !startDateTime
          ? 'Start TBD by Admin'
          : ['assessment', 'fd-training'].includes(contentType) &&
            getCompletedStatus(status, endDateTime, 'assessment') ===
              'NOT_COMPLETED'
          ? `Expired: ${getDateTimeZoneFormatted(endDateTime)}`
          : `Available: ${getDateTimeZoneFormatted(startDateTime)}`;
        break;
      case 'FINISHED':
        subHeading = `Completed: ${getDateTimeZoneFormatted(finishedOn)}`;
        break;
      case 'ENDED':
        subHeading = `Expired: ${getDateTimeZoneFormatted(endDateTime)}`;
        break;
      default:
        return null;
    }
    return subHeading;
  };

  // all assessmentData for the user except REMOVED & ARCHIVED
  const listUserAssessmentsData =
    assessmentsData?.listUserAssessmentsByUserId?.items
      .filter((ad) => !['REMOVED'].includes(ad?.status))
      .filter((ad) => ad?.assessment?.status !== 'ARCHIVED');

  const allAssessments =
    listUserAssessmentsData
      ?.filter((ad) => ad.assessment.participantEventType === 'ASSESSMENT')
      .map((ad) => ({
        ...ad,
        contentType: 'assessment',
        type: 'Solo',
        title: ad?.assessment?.name,
        duration: getDuration(ad.assessment.hours, ad.assessment.minutes),
        status:
          ad.status === 'STARTED'
            ? getCompletedStatus(
                ad.status,
                ad.assessment?.endDateTime,
                'assessment',
              )
            : ad.status,
        icon: (
          <Box style={productStyle('ASSESSMENT', theme)}>
            <AssignmentIcon style={{ height: '18px' }} />
          </Box>
        ),
        iconColor: productStyle('ASSESSMENT', theme)?.backgroundColor,
        unReadMessageCount: 0,
        image: getRandomImage(CONTENT_IMAGES),
        tasksCount: ad.assessment.tasks?.items?.length,
        eventStatus: getCompetitionStatus(
          ad.assessment?.startDateTime,
          ad.assessment?.endDateTime,
          serverTime?.getSystemTime,
        ),
      })) || [];

  const coursesData =
    listCourseUsersData?.listCourseUsers?.items
      ?.filter(
        (userCourse) =>
          userCourse.course?.status === 'AVAILABLE' &&
          userCourse.course?.availability,
      )
      .sort(
        (a, b) =>
          new Date(a?.course?.createdAt) - new Date(b?.course?.createdAt),
      )
      .map((userCourse) => {
        const lessonProgress = userCourse?.exerciseProgresses?.items || [];
        const totalLessons =
          userCourse?.course?.courseLessons?.items?.length || 0;
        const { status, percentageValue, completedLessons } =
          getCourseProgressStatus({
            lessonProgress,
            totalLessons,
          });

        return {
          ...userCourse,
          contentType: 'course',
          title: userCourse?.course?.name,
          image: userCourse?.course?.image?.key,
          icon: (
            <Box style={productStyle()}>
              <WbIncandescentIcon
                style={{
                  transform: 'rotateX(180deg)',
                  marginBottom: '5px',
                  height: '18px',
                }}
              />
            </Box>
          ),
          iconColor: productStyle('COURSE', theme)?.backgroundColor,
          unReadMessageCount:
            userCourse?.messages?.items?.filter(
              (m) => m?.receiverId === globalSnap?.userId,
            )?.length || 0,
          courseModulesCount:
            userCourse?.course?.courseLessons?.items?.length || 0,
          courseProgress: {
            value: percentageValue,
            totalLessons,
            completedLessons,
          },
          status,
          preComputedStatus: true,
          eventStatus: 'STARTED',
        };
      }) || [];

  const allCompetitions =
    listUserAssessmentsData
      ?.filter((ad) => ad.assessment.participantEventType === 'COMPETITION')
      .map((ad) => ({
        ...ad,
        contentType: 'competition',
        type: ad?.assessment?.teamBased ? 'Team' : 'Solo',
        title: ad?.assessment?.name,
        participantStatus:
          ['STARTED', 'NOT_STARTED'].includes(ad.status) &&
          ad.assessment?.endDateTime
            ? getCompletedStatus(
                ad.status,
                ad.assessment?.endDateTime,
                'competition',
              )
            : ad.status === 'FINISHED'
            ? 'ENDED'
            : ad.status,
        // status for competition refers to competition status not the participant status
        status: getCompetitionStatus(
          ad.assessment?.startDateTime,
          ad.assessment?.endDateTime,
          serverTime?.getSystemTime,
        ),
        icon: (
          <Box style={productStyle('COMPETITION', theme)}>
            <EmojiEventsIcon style={{ height: '18px' }} />
          </Box>
        ),
        iconColor: productStyle('COMPETITION', theme)?.backgroundColor,
        unReadMessageCount: 0,
        image: getRandomImage(CONTENT_IMAGES),
      })) || [];

  const allTrainings =
    listUserAssessmentsData
      ?.filter((ad) => ad.assessment.participantEventType === 'TRAINING')
      .map((ad) => ({
        ...ad,
        contentType: 'training',
        type: 'Solo',
        title: ad?.assessment?.name,
        duration: getDuration(ad.assessment.hours, ad.assessment.minutes),
        status:
          ad.status === 'STARTED'
            ? getCompletedStatus(
                ad.status,
                ad.assessment?.endDateTime,
                'assessment',
              )
            : ad.status,
        icon: (
          <Box
            className="flex items-center justify-center w-full h-full"
            style={{
              ...productStyle('TRAINING', theme),
              height: '200px',
              width: '32px',
            }}
          >
            <TrainingSvg />
          </Box>
        ),
        iconColor: productStyle('TRAINING', theme)?.backgroundColor,
        unReadMessageCount: 0,
        image: getRandomImage(CONTENT_IMAGES),
        tasksCount: ad.assessment.tasks?.items?.length,
      })) || [];

  const allFDTrainingUserAssessments =
    listUserAssessmentsData?.filter(
      (ad) => ad.assessment.participantEventType === 'FDTRAINING',
    ) || [];

  const allFDTrainings =
    fdTrainingsData?.listAssessmentsByParticipantEventType?.items?.map(
      (fdt) => {
        const userAssessment = allFDTrainingUserAssessments?.find(
          (ad) => ad?.assessment?.id === fdt?.id,
        );

        const _status = userAssessment
          ? userAssessment?.status
          : isDateInRange(
              serverTime?.getSystemTime,
              fdt?.startDateTime,
              fdt?.endDateTime,
            )
          ? 'NOT_STARTED'
          : 'ENDED';

        return {
          id: userAssessment?.id || fdt?.id,
          contentType: 'fd-training',
          type: 'Solo',
          title: fdt?.name,
          duration: 0,
          status: _status,
          icon: (
            <Box
              className="flex items-center justify-center w-full h-full"
              style={{
                ...productStyle('TRAINING', theme),
                height: '200px',
                width: '32px',
              }}
            >
              <TrainingSvg />
            </Box>
          ),
          iconColor: productStyle('TRAINING', theme)?.backgroundColor,
          unReadMessageCount: 0,
          image: getRandomImage(CONTENT_IMAGES),
          tasksCount: fdt?.tasks?.items?.length,
          eventStatus: getCompetitionStatus(
            fdt?.startDateTime,
            fdt?.endDateTime,
            serverTime?.getSystemTime,
          ),
          finishedOn: userAssessment?.finishedOn,
          startedOn: userAssessment?.startedOn,
          assessment: {
            endDateTime: fdt?.endDateTime,
            participantEventType: 'FDTRAINING',
          },
        };
      },
    ) || [];

  if (
    assessmentsLoading ||
    serverTimeLoading ||
    listCourseUsersLoading ||
    fdTrainingsLoading
  ) {
    return <FdLoadingSpinner />;
  }

  // rows sorted by updated date
  const rows = sortedByDate([
    ...allAssessments,
    ...allCompetitions,
    ...coursesData,
    ...allTrainings,
    // ...(globalSnap.isAffiliated ? allFDTrainings : []),
  ]);

  const inProgressEvents = rows?.filter((r) =>
    r.contentType === 'course'
      ? r.eventStatus === 'STARTED'
      : r.contentType === 'assessment'
      ? r.eventStatus === 'STARTED' &&
        ['STARTED', 'NOT_STARTED']?.includes(r.status)
      : r.contentType === 'fd-training'
      ? ['STARTED', 'NOT_STARTED']?.includes(r.status)
      : r.status === 'STARTED',
  );

  const upcomingEvents = rows?.filter((r) =>
    r.contentType !== 'fd-training' && r.contentType === 'assessment'
      ? r.eventStatus === 'NOT_STARTED'
      : r.status === 'NOT_STARTED',
  );
  const completedEvents = rows?.filter(
    (r) =>
      ['FINISHED', 'ENDED']?.includes(r.status) || r?.eventStatus === 'ENDED',
  );

  const competitionURL = ({ id, participantStatus }) =>
    participantStatus === 'NOT_STARTED'
      ? `/competitions/competition-start/${id}`
      : participantStatus === 'STARTED'
      ? `/competitions/competition-tasks/${id}`
      : `/competitions/competition-scoreboard/${id}`;

  const onEnterClick = ({ id, assessment, participantStatus, status }) => {
    const url =
      assessment?.participantEventType === 'ASSESSMENT'
        ? status === 'STARTED'
          ? `/assessor/assessment-tasks/${id}`
          : `/assessor/assessment-start/${id}`
        : assessment?.participantEventType === 'FDTRAINING'
        ? status === 'STARTED'
          ? `/assessor/fd-training/tasks/${id}`
          : `/assessor/fd-training/start/${id}`
        : assessment?.participantEventType === 'COMPETITION'
        ? competitionURL({ id, participantStatus })
        : assessment?.participantEventType === 'TRAINING'
        ? status === 'STARTED'
          ? `/competitions/training-tasks/${id}`
          : `/competitions/training-start/${id}`
        : assessment?.participantEventType === 'FDTRAINING'
        ? status === 'STARTED'
          ? `/assessor/fd-training/tasks/${id}`
          : `/assessor/fd-training/start/${id}`
        : `/labs/courses/entry/${id}/`;
    return singleSpa.navigateToUrl(url);
  };
  const getOrganizer = (row) => {
    if (row?.contentType === 'fd-training') {
      return 'FifthDomain';
    }
    if (row?.assessment) {
      return row?.assessment?.creator?.org?.name;
    }
    if (row?.course) {
      return row?.course?.user?.org?.name;
    }
    return '';
  };

  return (
    <Box>
      <FdBreadcrumbHeader page={{ name: 'My Events', type: 'EVENT' }} />
      <BasePage data-cy="my-events-page">
        <FdCard variant="outlined">
          <Box className="flex justify-between mb-4">
            <FdPageHeading type="EVENT">My Events</FdPageHeading>
          </Box>
          <FdTab
            label={[
              {
                label: (
                  <Box display="flex" alignItems="center">
                    <FdTypography variant="body2">{`Available Now (${inProgressEvents?.length})`}</FdTypography>
                  </Box>
                ),
                path: '/inProgressEvents',
                index: 0,
                data: (
                  <AssignedContentGrid
                    data={inProgressEvents?.map((row) => ({
                      ...row,
                      organizer: getOrganizer(row),
                      timestamp: row?.assessment ? timeStamp(row) : '-',
                    }))}
                    onEnterClick={onEnterClick}
                    eventStatus="available"
                  />
                ),
              },
              {
                label: (
                  <Box display="flex" alignItems="center">
                    <FdTypography variant="body2">{`Upcoming (${upcomingEvents?.length})`}</FdTypography>
                  </Box>
                ),
                path: '/upcomingEvents',
                index: 1,
                data: (
                  <AssignedContentGrid
                    data={upcomingEvents?.map((row) => ({
                      ...row,
                      organizer: getOrganizer(row),
                      timestamp: row?.assessment ? timeStamp(row) : '-',
                    }))}
                    onEnterClick={onEnterClick}
                    eventStatus="upcoming"
                  />
                ),
              },
              {
                label: (
                  <Box display="flex" alignItems="center">
                    <FdTypography variant="body2">{`Ended  (${completedEvents?.length})`}</FdTypography>
                  </Box>
                ),
                path: '/completedEvents',
                index: 2,
                data: (
                  <AssignedContentGrid
                    data={completedEvents?.map((row) => ({
                      ...row,
                      organizer: getOrganizer(row),
                      timestamp: row?.assessment ? timeStamp(row) : '-',
                    }))}
                    onEnterClick={onEnterClick}
                    eventStatus="ended"
                  />
                ),
              },
            ]}
          />
        </FdCard>
      </BasePage>
    </Box>
  );
};
export default MyEvents;
