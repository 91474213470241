import React, { useState } from 'react';
import _ from 'lodash';
import { Box, Grid, IconButton } from '@mui/material';
import makeStyles from '@mui/styles/makeStyles';
import PropTypes from 'prop-types';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  FdTypography,
  FdCard,
  FdTooltip,
  FdSkeleton,
} from '@fifthdomain/fe-shared';
import { TASK_TYPE_COLOR } from '../../shared/utils/taskUtils';
import { DonutStacked, DonutGraphLegend, LevelTooltip } from '../Charts';
import { upperCaseFirstLetter } from '../../shared/utils/stringUtils';
import {
  SPECIALTY_COLOR,
  PROFICIENCY_LEVELS,
  PROFICIENCY,
} from '../../constants';
import { getDifficultyLevel } from '../../shared/utils/difficultyMapping';

const HoverInfo = ({ value }) => {
  return (
    <Box
      width="50%"
      display="flex"
      justifyContent="center"
      alignItems="center"
      ml={2}
    >
      <FdTypography variant="subtitle1" color="secondary">
        {` Hover over the ${value} segment to see more details.`}
      </FdTypography>
    </Box>
  );
};
HoverInfo.propTypes = {
  value: PropTypes.string.isRequired,
};

const useStyles = makeStyles(() => ({
  card: {
    height: '400px',
    overflowY: 'auto',
  },
}));

const CompositionGraphs = ({ allTasks, loading }) => {
  const [donutToolTipData, setDonutToolTipData] = useState(undefined);
  const [showDonutGraphCategoryTooltip, setShowDonutGraphCategoryTooltip] =
    useState(false);
  const [showDonutGraphDifficultyTooltip, setShowDonutGraphDifficultyTooltip] =
    useState(false);
  const [showDonutGraphTaskTypeTooltip, setShowDonutGraphTaskTypeTooltip] =
    useState(false);
  const classes = useStyles();

  // Proficiency Composition
  const donutGraphProficiencyData = _.chain(
    allTasks
      ?.map((at) => ({
        id: at.difficulty,
        label:
          at?.difficulty > 5
            ? getDifficultyLevel(at?.difficulty)
            : PROFICIENCY_LEVELS[at?.difficulty],
      }))
      .filter((dv) => dv?.id),
  )
    .groupBy((i) => i?.label)
    .map((items, label) => ({
      id: label,
      label,
      value: items.length,
      color: PROFICIENCY[label]?.color,
    }))
    .filter((i) => i?.id !== 'null')
    .value();
  const donutGraphDifficultyDataColors = donutGraphProficiencyData?.map(
    (d) => d.color,
  );

  // Specialty Composition
  const donutGraphSpecialtyData = _.chain(
    allTasks
      .map((t) => ({
        id: t?.specialtyId,
        name: t?.specialty?.name,
      }))
      .filter((tc) => tc?.id),
  )
    .groupBy((i) => i?.id)
    .map((items, id) => ({
      id,
      label: items[0].name,
      value: items.length,
      color: SPECIALTY_COLOR[items[0]?.name] || '',
    }))
    .value();
  const donutGraphCategoryDataColors = donutGraphSpecialtyData?.map(
    (d) => d.color,
  );

  // Task type Composition
  const getTasksByType = (_tasks, _type) =>
    _tasks?.filter((t) => _type === t.type);

  const donutGraphTypeData = (_allTasks) => {
    const taskType = [
      ...new Set(_allTasks.map((item) => item?.type).filter((t) => t)),
    ];
    const taskTypeValue = [];
    for (let i = 0; i < taskType?.length; i += 1) {
      taskTypeValue.push({
        id: taskType[i],
        label: upperCaseFirstLetter(taskType[i]),
        value: getTasksByType(_allTasks, taskType[i])?.length,
        color: TASK_TYPE_COLOR[upperCaseFirstLetter(taskType[i])],
      });
    }
    return taskTypeValue;
  };
  const donutGraphTypeDataColors = donutGraphTypeData(allTasks).map((d) => {
    return d.color;
  });

  return (
    <Grid container spacing={1}>
      <Grid item xs={4}>
        <FdSkeleton loading={loading} height="416px" width="320px">
          <FdCard elevation={0} className={classes.card}>
            <Box display="flex" flexDirection="column" mb={2}>
              <Box display="flex">
                <FdTypography variant="subtitle1">
                  Professional Specialty composition
                </FdTypography>
                <FdTooltip title="Professional specialties represent broad categories of commonly found technical functions within the field of cyber operations. These specialties offer a structured framework under which a particular challenge or task can be classified.">
                  <IconButton size="small" style={{ marginLeft: '5px' }}>
                    <InfoOutlinedIcon />
                  </IconButton>
                </FdTooltip>
              </Box>
              <Box display="flex" flexDirection="row" mt={2} mb={2}>
                <Box height="200px" width="200px" mr={2}>
                  <DonutStacked
                    data={donutGraphSpecialtyData}
                    colors={donutGraphCategoryDataColors}
                    onHover={(_data) => {
                      setDonutToolTipData(_data?.data);
                      setShowDonutGraphCategoryTooltip(true);
                    }}
                    onLeave={() => {
                      setDonutToolTipData(undefined);
                      setShowDonutGraphCategoryTooltip(false);
                    }}
                  />
                </Box>
                {showDonutGraphCategoryTooltip ? (
                  <LevelTooltip
                    data={donutToolTipData}
                    totalTasks={allTasks.length}
                  />
                ) : (
                  allTasks?.length > 0 && <HoverInfo value="specialty" />
                )}
              </Box>
              <DonutGraphLegend data={donutGraphSpecialtyData} />
            </Box>
          </FdCard>
        </FdSkeleton>
      </Grid>
      <Grid item xs={4}>
        <FdSkeleton loading={loading} height="416px" width="320px">
          <FdCard elevation={0} className={classes.card}>
            <Box display="flex" flexDirection="column" mb={2}>
              <Box display="flex">
                <FdTypography variant="subtitle1">
                  Proficiency composition
                </FdTypography>
                <FdTooltip title="The proficiency level for a challenge specifies the required expertise to solve that challenge ranging from Novice, Advanced Beginner, Competent, Proficient and Expert.">
                  <IconButton size="small" style={{ marginLeft: '5px' }}>
                    <InfoOutlinedIcon />
                  </IconButton>
                </FdTooltip>
              </Box>
              <Box display="flex" flexDirection="row" mt={2} mb={2}>
                <Box height="200px" width="200px" mr={2}>
                  <DonutStacked
                    data={donutGraphProficiencyData}
                    colors={donutGraphDifficultyDataColors}
                    onHover={(_data) => {
                      setDonutToolTipData(_data?.data);
                      setShowDonutGraphDifficultyTooltip(true);
                    }}
                    onLeave={() => {
                      setDonutToolTipData(undefined);
                      setShowDonutGraphDifficultyTooltip(false);
                    }}
                  />
                </Box>
                {showDonutGraphDifficultyTooltip ? (
                  <LevelTooltip
                    data={donutToolTipData}
                    totalTasks={allTasks.length}
                  />
                ) : (
                  allTasks?.length > 0 && <HoverInfo value="proficiency" />
                )}
              </Box>
              <DonutGraphLegend data={donutGraphProficiencyData} />
            </Box>
          </FdCard>
        </FdSkeleton>
      </Grid>
      <Grid item xs={4}>
        <FdSkeleton loading={loading} height="416px" width="320px">
          <FdCard elevation={0} className={classes.card}>
            <Box display="flex" flexDirection="column" mb={2}>
              <Box display="flex">
                <FdTypography variant="subtitle1">
                  Type composition
                </FdTypography>
                <FdTooltip
                  title={
                    <Box>
                      The challenge type determines the format, and there are
                      three types:
                      <br />
                      1. Static Challenges: These feature static artifacts like
                      PDFs, images, and markdown files.
                      <br />
                      2.Lab-based Challenges: These come with an attached lab
                      environment.
                    </Box>
                  }
                >
                  <IconButton size="small" style={{ marginLeft: '5px' }}>
                    <InfoOutlinedIcon />
                  </IconButton>
                </FdTooltip>
              </Box>
              <Box display="flex" flexDirection="row" mt={2} mb={2}>
                <Box height="200px" width="200px" mr={2}>
                  <DonutStacked
                    data={donutGraphTypeData(allTasks)}
                    colors={donutGraphTypeDataColors}
                    onHover={(_data) => {
                      setDonutToolTipData(_data?.data);
                      setShowDonutGraphTaskTypeTooltip(true);
                    }}
                    onLeave={() => {
                      setDonutToolTipData(undefined);
                      setShowDonutGraphTaskTypeTooltip(false);
                    }}
                  />
                </Box>
                {showDonutGraphTaskTypeTooltip ? (
                  <LevelTooltip
                    data={donutToolTipData}
                    totalTasks={allTasks.length}
                  />
                ) : (
                  allTasks?.length > 0 && <HoverInfo value="type" />
                )}
              </Box>
              <DonutGraphLegend data={donutGraphTypeData(allTasks)} />
            </Box>
          </FdCard>
        </FdSkeleton>
      </Grid>
    </Grid>
  );
};

CompositionGraphs.propTypes = {
  allTasks: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
      name: PropTypes.string,
    }).isRequired,
  ),
  loading: PropTypes.bool.isRequired,
};
CompositionGraphs.defaultProps = {
  allTasks: [],
};

export default CompositionGraphs;
