import React, { useState } from 'react';
import { Box, FormGroup, FormControlLabel, Switch } from '@mui/material';
import { Controller, useFormContext } from 'react-hook-form';
import { FdCard, FdTypography } from '@fifthdomain/fe-shared';
import ProfileHeading from './ProfileHeading';

const JobPool = () => {
  const [editMode, setEditMode] = useState(false);
  const { control, setValue, getValues } = useFormContext();

  return (
    <FdCard
      heading={
        <ProfileHeading
          heading="Job Pool"
          editMode={editMode}
          setEditMode={setEditMode}
        />
      }
    >
      <Controller
        control={control}
        name="jobPool"
        render={({
          field: { ref, value: fieldValue, ...rest },
          fieldState: { error },
        }) => (
          <FormGroup>
            <Box
              display="flex"
              flexDirection="row"
              justifyContent="space-between"
            >
              <Box>
                <FdTypography variant="subtitle1">
                  Opt In to Job Pool
                </FdTypography>
              </Box>
              {editMode ? (
                <FormControlLabel
                  control={
                    <Switch
                      id="jobPool"
                      checked={fieldValue}
                      value={fieldValue?.value}
                      error={error && error.message}
                      data-cy="jobPool"
                      {...rest}
                      onChange={(e) => {
                        setValue('jobPool', e.target.checked);
                      }}
                      inputRef={ref}
                    />
                  }
                />
              ) : (
                <Switch checked={getValues('jobPool')} />
              )}
            </Box>
          </FormGroup>
        )}
      />
    </FdCard>
  );
};

export default JobPool;
