import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { Box } from '@mui/material';
import { useMutation, gql } from '@apollo/client';
import {
  Controller,
  useFormContext,
  useFieldArray,
  useWatch,
} from 'react-hook-form';
import InfoOutlinedIcon from '@mui/icons-material/InfoOutlined';
import {
  FdCard,
  FdTypography,
  FdButton,
  FdTooltip,
  FdModal,
  FdAccordion,
  FdSelect,
  FdDelayed,
} from '@fifthdomain/fe-shared';
import {
  warningToastMessage,
  successToastMessage,
} from '../../shared/utils/toast';
import {
  createAccomplishment,
  updateAccomplishment,
  deleteAccomplishment,
} from '../../graphql/mutations';
import FdBoxButton from './FdBoxButton';
import { ACCOMPLISHMENT_TYPES } from '../../constants';
import AccomplishmentType from './AccomplishmentType';
import { getKeyByValue } from '../../shared/utils/objectUtils';
import AccomplishmentView from './AccomplishmentView';

const Accomplishments = ({ refetchUserData }) => {
  const [editMode, setEditMode] = useState(false);
  const {
    control,
    trigger,
    setValue,
    reset,
    getValues,
    formState: { errors },
    clearErrors,
  } = useFormContext();

  const {
    fields: addAccomplishmentFields,
    remove: addAccomplishmentRemove,
    append: addAccomplishmentAppend,
  } = useFieldArray({
    control,
    name: 'addAccomplishment',
  });

  const { append: appendDeleteAccomplishment } = useFieldArray({
    control,
    name: 'accomplishmentsToDelete',
  });

  // reset form on invocation
  useEffect(() => reset(), [reset, editMode]);

  const watchAccomplishment = useWatch({
    control,
    name: 'addAccomplishment',
  });

  const [createAccomplishmentMutation] = useMutation(gql(createAccomplishment));
  const [updateAccomplishmentMutation] = useMutation(gql(updateAccomplishment));
  const [deleteAccomplishmentMutation] = useMutation(gql(deleteAccomplishment));

  return (
    <FdCard
      variant="outlined"
      heading={
        editMode ? (
          <FdModal
            size="lg"
            title="Edit Self-Reported Accomplishments"
            description="Select the type of accomplishment you wish to add, and fill in the required details. You may delete accomplishments as you like from this list. "
            dismiss="Cancel"
            disableConfirm={errors?.addAccomplishment}
            confirm="Save"
            open={editMode}
            onDismiss={() => {
              setEditMode(undefined);
              warningToastMessage('Self-reported accomplishments not updated');
            }}
            onConfirm={async () => {
              const _accomplishmentTrigger = await trigger([
                'addAccomplishment',
              ]);
              if (_accomplishmentTrigger) {
                const {
                  id: userId,
                  addAccomplishment,
                  accomplishmentsToDelete,
                } = getValues();

                if (addAccomplishment) {
                  // create or update mutation
                  await addAccomplishment?.map(async (a) => {
                    const actionMutation = a?.id
                      ? updateAccomplishmentMutation
                      : createAccomplishmentMutation;
                    const actionId = a.id ? { id: a.id } : undefined;
                    const currentYear =
                      a?.currentYear && String(new Date().getFullYear());
                    await actionMutation({
                      variables: {
                        input: {
                          type: getKeyByValue(ACCOMPLISHMENT_TYPES, a?.type),
                          name: a?.accName,
                          providerName: a?.providerName,
                          userId,
                          startYear: a?.startYear ? a?.startYear : '',
                          endYear: a?.endYear || currentYear || '',
                          yearAwarded: a?.yearAwarded ? a?.yearAwarded : '',
                          ...actionId,
                        },
                      },
                      onCompleted: () => {
                        refetchUserData();
                        setEditMode(undefined);
                        successToastMessage(
                          'Self-reported accomplishments updated!',
                        );
                      },
                    });
                  });
                }
                // delete mutation
                accomplishmentsToDelete?.map(async (m) => {
                  await Promise.all(
                    await deleteAccomplishmentMutation({
                      variables: {
                        input: {
                          id: m,
                        },
                      },
                      onCompleted: () => {
                        refetchUserData();
                        setEditMode(undefined);
                        successToastMessage(
                          'Self-reported accomplishments updated!',
                        );
                      },
                    }),
                  );
                });
              }
            }}
            data-cy="task-details-modal"
          >
            <Box mt={3}>
              {addAccomplishmentFields.map((item, index) => {
                const accomplishmentType = watchAccomplishment?.[index]?.type;
                return (
                  <Box my={2} key={item.id}>
                    <FdAccordion
                      open
                      startAdornment
                      showToggleButton={false}
                      summary={() => (
                        <Box display="flex" width="100%">
                          <FdTypography variant="h3">
                            New Accomplishment
                          </FdTypography>
                        </Box>
                      )}
                      actions={
                        <Box display="flex" alignItems="center">
                          <Controller
                            control={control}
                            name={`addAccomplishment[${index}].type`}
                            render={({
                              field: { id, ref, value: fieldValue, ...rest },
                              fieldState: { error },
                            }) => (
                              <FdDelayed>
                                <Box width="300px">
                                  <FdSelect
                                    key={id}
                                    id={`addAccomplishment[${index}].type`}
                                    label=""
                                    options={Object.values(
                                      ACCOMPLISHMENT_TYPES,
                                    )}
                                    defaultSelected={fieldValue}
                                    fullWidth
                                    error={error}
                                    helperText={error && error.message}
                                    data-cy={`addAccomplishment[${index}].type`}
                                    placeholder="Select Type"
                                    {...rest}
                                    inputRef={ref}
                                    onChange={(value) => {
                                      setValue(
                                        `addAccomplishment[${index}].type`,
                                        value,
                                      );
                                    }}
                                    onFocus={() => {
                                      clearErrors();
                                    }}
                                  />
                                </Box>
                              </FdDelayed>
                            )}
                          />
                          <Box ml={1}>
                            <FdButton
                              variant="secondary"
                              size="small"
                              onClick={(e) => {
                                e.preventDefault();
                                const actionId = item.accId;
                                if (actionId) {
                                  // delete from db if present on save
                                  appendDeleteAccomplishment(actionId);
                                }
                                addAccomplishmentRemove(index);
                              }}
                            >
                              DELETE
                            </FdButton>
                          </Box>
                        </Box>
                      }
                      content={() => (
                        <Box display="flex" flexDirection="column">
                          <AccomplishmentType
                            accIndex={index}
                            accomplishmentType={accomplishmentType}
                          />
                        </Box>
                      )}
                    />
                  </Box>
                );
              })}
              <FdBoxButton
                onClick={async (e) => {
                  e.preventDefault();
                  addAccomplishmentAppend({});
                }}
              >
                Add accomplishment
              </FdBoxButton>
            </Box>
          </FdModal>
        ) : (
          <Box display="flex" justifyContent="space-between">
            <Box className="flex gap-2">
              <FdTypography variant="h3">
                Self-Reported Accomplishments
              </FdTypography>
              <FdTooltip
                title="This section allows you to highlight your professional accomplishments achieved outside of the platform. 
              If you are affiliated with an organisation on the platform, the admins of that organisation will be able to see this information 
              when viewing your profile."
              >
                <Box pt={0.3}>
                  <InfoOutlinedIcon />
                </Box>
              </FdTooltip>
            </Box>
            <FdButton
              variant="primary"
              size="small"
              onClick={() => setEditMode(true)}
            >
              Edit
            </FdButton>
          </Box>
        )
      }
    >
      {Object.values(ACCOMPLISHMENT_TYPES).map((a) => (
        <AccomplishmentView
          accomplishment={a}
          watchAccomplishment={watchAccomplishment}
        />
      ))}
    </FdCard>
  );
};

Accomplishments.propTypes = {
  refetchUserData: PropTypes.func.isRequired,
};

export default Accomplishments;
