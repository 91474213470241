import React, { useEffect } from 'react';
import StylesProvider from '@mui/styles/StylesProvider';
import createGenerateClassName from '@mui/styles/createGenerateClassName';
import { BrowserRouter, Switch, Redirect } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { AuthProvider } from '@fifthdomain/auth';
import {
  setShowNav,
  AppBasePageProvider,
  useSnapshot,
  globalStore,
  ReactQueryProvider,
} from '@fifthdomain/fe-shared';
import GraphQlClient from './shared/GraphQlClient';
import AuthRoute from './routes/AuthRoute';
import AppThemeProvider from './providers/AppThemeProvider';
import AppStoreInitializer from './providers/AppStoreInitializer';
import AdminLandingPage from './pages/AdminLandingPage';
import OrganisationProfile from './pages/OrganisationProfile';
import AdminRoute from './routes/AdminRoute';
import ManageUserProfile from './pages/ManageUserProfile';
import TeamsLandingPage from './pages/TeamsLandingPage';
import ParticipantRoute from './routes/ParticipantRoute';
import ParticipantLandingPage from './pages/ParticipantLandingPage';
import AffiliatedRoute from './routes/AffiliatedRoute';
import HallOfFame from './pages/HallOfFame';
import SubscriptionProvider from './providers/SubscriptionProvider';
import MyEvents from './pages/MyEvents';
import AdminOrgProfileRoute from './routes/AdminOrgProfileRoute';
import './landing.css';

const generateClassName = createGenerateClassName({
  productionPrefix: 'landing',
  seed: 'landing',
});

const App = () => {
  const globalSnap = useSnapshot(globalStore);

  useEffect(() => {
    setShowNav(true);
  });

  // wait until globalStore is initialized
  if (globalSnap.loading) {
    return null;
  }

  return (
    <GraphQlClient>
      <AuthProvider>
        <ReactQueryProvider>
          <AppThemeProvider>
            <StylesProvider generateClassName={generateClassName}>
              <AppStoreInitializer>
                <ToastContainer />
                <AppBasePageProvider>
                  <SubscriptionProvider>
                    <BrowserRouter>
                      <Switch>
                        <ParticipantRoute
                          path="/landing/teams"
                          component={TeamsLandingPage}
                          exact
                        />
                        <ParticipantRoute
                          path="/landing/landing-homepage"
                          component={ParticipantLandingPage}
                          exact
                        />
                        <ParticipantRoute
                          path="/landing/landing-homepage/my-events"
                          component={MyEvents}
                        />
                        <AffiliatedRoute
                          path="/landing/hall-of-fame"
                          component={HallOfFame}
                          exact
                        />
                        <AuthRoute
                          path="/landing/user/profile"
                          component={ManageUserProfile}
                          exact
                        />
                        <AdminRoute
                          path="/landing"
                          component={AdminLandingPage}
                          exact
                        />
                        <AdminOrgProfileRoute
                          path="/landing/org/profile"
                          component={OrganisationProfile}
                          exact
                        />
                        <Redirect to="/" />
                      </Switch>
                    </BrowserRouter>
                  </SubscriptionProvider>
                </AppBasePageProvider>
              </AppStoreInitializer>
            </StylesProvider>
          </AppThemeProvider>
        </ReactQueryProvider>
      </AuthProvider>
    </GraphQlClient>
  );
};

export default App;
