import React, { useState } from 'react';
import { Box, Grid, Button, useTheme, TextField } from '@mui/material';
import { gql, useQuery } from '@apollo/client';
import * as singleSpa from 'single-spa';
import _ from 'lodash';
import AssignmentIcon from '@mui/icons-material/Assignment';
import EmojiEventsIcon from '@mui/icons-material/EmojiEvents';
import WbIncandescentIcon from '@mui/icons-material/WbIncandescent';
import ContentCopyIcon from '@mui/icons-material/ContentCopy';
import ArchiveIcon from '@mui/icons-material/Archive';
import BallotIcon from '@mui/icons-material/Ballot';
import CreateIcon from '@mui/icons-material/Create';
import HomeIcon from '@mui/icons-material/Home';
import {
  BasePage,
  FdTypography,
  FdCard,
  useQueryRecursive,
  useSnapshot,
  globalStore,
  FdTab,
  FdSelect,
  FdButton,
  FdModal,
  FdDelayed,
  FdAlert,
  FdAutocompleteStatic,
  FdSkeleton,
  Authorization,
  PERMISSIONS_AFFILIATED,
} from '@fifthdomain/fe-shared';
import { FdBreadcrumbHeader } from '@fifthdomain/sidebar';
import {
  listAssessmentsByOrg,
  listCoursesByOrgId,
  getSystemTime,
  listTasksByOrgId,
  listSharedAssessmentBySharedOrgId,
  listTaskOrgs,
  queryTemplatesByStatus,
  listAssessmentsByParticipantEventType,
} from '../graphql/queries';
import { productStyle } from '../shared/utils/layout';
import {
  getAssessmentStatus,
  getReleasedTemplatesChallenges,
} from '../shared/utils/taskUtils';
import {
  getDateTimeZoneFormatted,
  sortedByCreatedDate,
} from '../shared/utils/dateUtils';
import { getCourseStatus } from '../shared/utils/courseStatus';
import EventTable from '../components/Contents/EventTable';
import Summary from '../components/Contents/Summary';
import CompositionGraphs from '../components/Composition/CompositionGraphs';
import OrgUsers from '../components/UserInfo/OrgUsers';
import { ORG_PRODUCTS } from '../constants';
import NoContentImage from '../shared/images/no-content-data.svg';
import AffiliationTag from '../components/Affiliated/AffiliationTag';
import AffiliationLinkStatusModal from '../components/Affiliated/LandingMessageModals/AffiliationLinkStatusModal';
import SquadTag from '../components/Affiliated/SquadTag';
import FDTrainingTable from '../components/Contents/FDTrainingTable';

const AdminLandingPage = () => {
  const globalSnap = useSnapshot(globalStore);
  const [openModal, setOpenModal] = useState(undefined);
  const [selectedTemplateOption, setSelectedTemplateOption] = useState(null);
  const [eventType, setEventType] = useState(undefined);
  const [createEventType, setCreateEventType] = useState(undefined);
  const theme = useTheme();
  // Event section only if I have manage_events or view insights permission.
  const hasManageEvents =
    Authorization.canManageEvents(globalSnap?.permissions) ||
    Authorization.canViewInsights(globalSnap?.permissions);
  // user section only if I have manage_users permission.
  const hasManageUser = Authorization.canManageUsers(globalSnap?.permissions);
  const hasManageContent = Authorization.hasPermission(
    globalSnap?.permissions,
    [
      PERMISSIONS_AFFILIATED.MANAGE_CONTENT,
      PERMISSIONS_AFFILIATED.CREATE,
      PERMISSIONS_AFFILIATED.REVIEW_CONTENT,
    ],
  );

  const canCreateAndReview =
    Authorization.canCreate(globalSnap?.permissions) &&
    Authorization.canReviewContent(globalSnap?.permissions);

  const pricingTier = globalSnap.orgPricingTier;
  const orgProducts = globalSnap?.orgProducts;

  const { data: serverTime } = useQuery(gql(getSystemTime), {
    fetchPolicy: 'network-only',
  });

  const { data: listTasksByOrgIdData, loading: listTasksByOrgIdLoading } =
    useQueryRecursive(gql(listTasksByOrgId), {
      variables: {
        orgId: globalSnap.orgId,
        limit: 1000,
      },
      skip: !globalSnap.orgId,
    });

  const { data: listFdTrainingsData } = useQueryRecursive(
    gql(listAssessmentsByParticipantEventType),
    {
      variables: {
        orgId: globalSnap.orgId,
        participantEventType: { eq: 'FDTRAINING' },
        limit: 1000,
      },
      skip: !globalSnap.orgId,
    },
  );

  const { data: allTasksAvailableToOrg, loading: allTasksAvailableLoading } =
    useQueryRecursive(gql(listTaskOrgs), {
      variables: {
        filter: {
          orgId: { eq: globalSnap.orgId },
        },
        limit: 1000,
      },
      fetchPolicy: 'cache-and-network',
      skip: !globalSnap.orgId,
    });

  const { data: listCoursesByOrgIdData, loading: listCoursesByOrgIdLoading } =
    useQueryRecursive(gql(listCoursesByOrgId), {
      variables: {
        orgId: globalSnap.orgId,
        filter: {
          status: {
            ne: 'DELETED',
          },
        },
      },
      skip: globalSnap.userType === 'PARTICIPANT' || !hasManageEvents,
    });

  const {
    data: listSharedAssessmentsByOrgData,
    refetch: refetchSharedAssessmentData,
  } = useQueryRecursive(gql(listSharedAssessmentBySharedOrgId), {
    variables: {
      sharedOrgId: globalSnap.orgId,
    },
    skip: !globalSnap.orgId || !hasManageEvents,
  });

  const {
    data: listAssessmentsByOrgData,
    loading: listAssessmentsByOrgLoading,
    refetch: refetchAssessmentData,
  } = useQueryRecursive(gql(listAssessmentsByOrg), {
    variables: {
      orgId: globalSnap.orgId,
    },
    onCompleted: async () => {
      await refetchSharedAssessmentData();
    },
    skip: !globalSnap.orgId || !hasManageEvents,
  });

  const { data: releasedTemplates, loading: templatesLoading } =
    useQueryRecursive(gql(queryTemplatesByStatus), {
      variables: {
        status: 'RELEASED',
      },
    });

  const releasedTemplatesChallenges =
    globalSnap.orgPricingTier === 'STARTER'
      ? []
      : getReleasedTemplatesChallenges(
          releasedTemplates?.queryTemplatesByStatus?.items || [],
        );

  const taskTable = _.uniqBy(
    [
      ...(listTasksByOrgIdData?.listTasksByOrgId?.items.map((task) => ({
        ...task,
        reviewers: task?.reviewers?.items?.map((item) => item?.assigneeId),
        owned: true,
      })) || []),
      ...(allTasksAvailableToOrg?.listTaskOrgs?.items
        ?.map((taskOrg) => ({
          ...taskOrg.task,
          ownerOrg: taskOrg?.org?.name,
        }))
        ?.map((task) => ({
          ...task,
          owned: false,
        })) || []),
      ...(releasedTemplatesChallenges?.map((challenge) => ({
        ...challenge,
        owned: challenge?.orgId === globalSnap?.orgId,
      })) || []),
    ],
    'id',
  ).filter((_task) => _task.type !== 'CONTAINER');

  const allTasks = Authorization.hasPermission(globalSnap?.permissions, [
    PERMISSIONS_AFFILIATED.MANAGE_CONTENT,
  ])
    ? taskTable
    : canCreateAndReview
    ? [
        ...(taskTable?.filter((t) => t?.userId === globalSnap?.userId) || []),
        ...(taskTable?.filter((t) =>
          t?.reviewers?.includes(globalSnap?.userId),
        ) || []),
      ]
    : (Authorization.canCreate(globalSnap?.permissions) &&
        taskTable?.filter((t) => t?.userId === globalSnap?.userId)) ||
      (Authorization.canReviewContent(globalSnap?.permissions) &&
        taskTable?.filter((t) => t?.reviewers?.includes(globalSnap?.userId)));

  // all events including shared
  const allEventsData = [
    ...(listAssessmentsByOrgData?.listAssessmentsByOrg?.items || []),
    ...(listSharedAssessmentsByOrgData?.listSharedAssessmentBySharedOrgId?.items?.map(
      (sa) => sa?.assessment,
    ) || []),
  ];

  const eventsData =
    allEventsData
      ?.filter((e) => e?.participantEventType !== 'TRAINING')
      ?.map((assessment) => {
        const _eventType =
          assessment?.participantEventType === 'ASSESSMENT' ? 'assess' : 'comp';
        const eventUrl = `${window.origin}/a/login/e/${_eventType}/${assessment?.id}/org/${globalSnap.orgId}`;

        return {
          ...assessment,
          creator: assessment?.creator?.name,
          startDateTime: assessment.startDateTime
            ? getDateTimeZoneFormatted(assessment.startDateTime, true)
            : '-',
          endDateTime: assessment.endDateTime
            ? getDateTimeZoneFormatted(assessment.endDateTime, true)
            : '-',
          status:
            !assessment.startDateTime && !assessment.endDateTime
              ? 'Not Started'
              : assessment &&
                serverTime &&
                getAssessmentStatus(
                  assessment?.startDateTime,
                  assessment?.endDateTime,
                  new Date(serverTime?.getSystemTime),
                ),
          archived: assessment.status === 'ARCHIVED',
          type: assessment.teamBased ? 'Team' : 'Individual',
          parts: assessment?.tasks?.items?.length,
          eventLink: !assessment?.teamBased && (
            <Button
              onClick={() => {
                navigator.clipboard.writeText(eventUrl);
              }}
              style={{ height: '48px', color: 'rgba(0, 0, 0, 0.95)' }}
            >
              <ContentCopyIcon />
            </Button>
          ),
          icon: (
            <Box style={productStyle(assessment?.participantEventType, theme)}>
              {assessment?.participantEventType === 'ASSESSMENT' ? (
                <AssignmentIcon style={{ height: '18px' }} />
              ) : (
                <EmojiEventsIcon style={{ height: '18px' }} />
              )}
            </Box>
          ),
        };
      }) || [];

  const allCompetitions = eventsData
    ?.filter(
      (allCompetition) =>
        allCompetition.participantEventType === 'COMPETITION' &&
        !allCompetition?.archived,
    )
    ?.map((event) => ({
      ...event,
      eventType: event?.archived ? 'Archived' : 'Competition',
    }));

  const allAssessments = eventsData
    ?.filter(
      (allAssessment) =>
        allAssessment.participantEventType !== 'COMPETITION' &&
        !allAssessment?.archived,
    )
    ?.map((event) => ({
      ...event,
      eventType: event?.archived ? 'Archived' : 'Assessment',
    }));

  const allArchivedEvents = eventsData
    ?.filter((item) => item?.archived)
    ?.map((event) => ({
      ...event,
      eventType: 'Archived',
    }));

  const getUnreadMessageUserCount = (_items = []) => {
    // no of users who have send messages which are unread
    const noOfWaitingUsers = _items.reduce(
      (acc, i) =>
        acc +
        (i.messages?.items?.filter((m) => m?.sender?.id === i.userId)?.length >
        0
          ? 1
          : 0),
      0,
    );
    return noOfWaitingUsers;
  };

  const allCourses =
    listCoursesByOrgIdData?.listCoursesByOrgId?.items.map((ac) => {
      return {
        ...ac,
        id: ac?.id,
        creator: ac?.user?.name || '',
        unReadMessagesUserCount: getUnreadMessageUserCount(
          ac.courseUsers?.items || [],
        ),
        icon: (
          <Box style={productStyle('COURSE', theme)}>
            <WbIncandescentIcon
              style={{
                transform: 'rotateX(180deg)',
                marginBottom: '5px',
                height: '18px',
              }}
            />
          </Box>
        ),
        parts: ac?.courseLessons?.items?.length,
        courseUserId:
          ac?.status === 'AVAILABLE' &&
          ac?.courseUsers?.items?.find(
            (courseUser) => courseUser?.userId === globalSnap?.userId,
          )?.id,
        status: !ac.availability
          ? getCourseStatus.NOT_AVAILABLE
          : getCourseStatus[ac.status],
        eventType: 'Course',
      };
    }) || [];

  const allEvents = sortedByCreatedDate([
    ...allAssessments,
    ...allCompetitions,
    ...allCourses,
    ...allArchivedEvents,
  ]);
  const eventIcons = {
    COURSE: (
      <WbIncandescentIcon
        className="size-4"
        style={{ transform: 'rotateX(180deg)' }}
      />
    ),
    ASSESSMENT: <AssignmentIcon className="size-4" />,
    COMPETITION: <EmojiEventsIcon className="size-4" />,
    ARCHIVED: <ArchiveIcon className="size-4" />,
  };
  const fdUserAssessments =
    listFdTrainingsData?.listAssessmentsByParticipantEventType?.items || [];
  const fdTrainings = eventsData
    ?.filter((item) => item?.participantEventType === 'FDTRAINING')
    ?.map((event) => {
      const userAssessments = fdUserAssessments?.find(
        (fd) => fd?.id === event?.id,
      );
      const usersStarted =
        userAssessments?.users?.items?.filter((ua) => ua?.status === 'STARTED')
          ?.length || 0;
      const usersCompleted =
        userAssessments?.users?.items?.filter((ua) => ua?.status === 'FINISHED')
          ?.length || 0;
      const taskIds = event?.tasks?.items?.map((t) => t?.taskId) || [];
      const taskMappings = allTasks?.filter((t) => taskIds.includes(t?.id));
      const specialties = [
        ...new Set(taskMappings?.map((tm) => tm?.specialty?.name) || []),
      ];
      const proficiencies = [
        ...new Set(taskMappings?.map((tm) => tm?.difficulty) || []),
      ];
      return {
        ...event,
        started: usersStarted,
        completed: usersCompleted,
        specialties,
        proficiencies,
      };
    });

  const tabLabel = [
    {
      label: `FD Trainings (${fdTrainings.length})`,
      path: '/all',
      index: 0,
      data: (
        <FdSkeleton loading={listAssessmentsByOrgLoading} height="450px">
          <FdAlert
            variant="info"
            message="This table lists all currently available FD Trainings. Click 'View' to see insights from affiliated participants in your organisation. After the availability period ends, FD Trainings will be removed from the table, but you can still access skill and performance data in individual user profiles."
            className="mb-4"
          />
          <FDTrainingTable rows={fdTrainings} />
        </FdSkeleton>
      ),
    },
    {
      label: `Organisation Events (${allEvents.length})`,
      index: 1,
      data: (
        <FdSkeleton loading={listAssessmentsByOrgLoading} height="450px">
          <FdAlert
            variant="info"
            message="Note that Trainings created by users in your organisation are not displayed in the table below, as Trainings are specific to individual squads. Trainings by users in your organisation are created and managed directly within Squad profiles by Squad Managers."
            className="mb-4"
          />
          <Box
            className="flex items-center justify-between border-x rounded-md p-3 border-t border-b-0"
            sx={{ borderColor: theme?.palette?.table?.border }}
          >
            <FdAutocompleteStatic
              options={[
                ...(orgProducts?.map((product) => ORG_PRODUCTS[product]) || []),
                'Archived',
              ]?.map((option) => ({
                label: option,
              }))}
              sx={{ width: '300px' }}
              onChange={(__, _value) => {
                setEventType(_value?.label);
              }}
              renderOption={(props, option) => {
                // eslint-disable-next-line react/prop-types
                const { key, ...optionProps } = props;
                const label = option?.label?.toUpperCase();
                return (
                  <Box key={key} component="li" {...optionProps}>
                    <Box
                      className="flex items-center size-6 rounded mr-2"
                      style={productStyle(label, theme)}
                    >
                      {eventIcons[label]}
                    </Box>
                    {option?.label}
                  </Box>
                );
              }}
              renderInput={(params) => (
                <TextField placeholder="Filter by Event Type" {...params} />
              )}
              data-cy="filer-event-type"
            />
            {Authorization.canManageEvents(globalSnap?.permissions) && (
              <FdDelayed triggerField={createEventType}>
                <Box>
                  <FdSelect
                    options={
                      orgProducts?.map((product) => ORG_PRODUCTS[product]) || []
                    }
                    width="160px"
                    placeholder="CREATE"
                    customPlaceHolder
                    onChange={(_value) => {
                      const handleOpenModal = () => {
                        setCreateEventType(_value);
                        setOpenModal(_value);
                      };
                      switch (_value) {
                        case 'Course':
                          return singleSpa.navigateToUrl(
                            '/labs/courses/create',
                          );
                        case 'Competition':
                          return singleSpa.navigateToUrl(
                            '/competitions/create',
                          );
                        case 'Assessment':
                          return pricingTier === 'STARTER'
                            ? singleSpa.navigateToUrl(
                                '/assessor/create/template',
                              )
                            : handleOpenModal();
                        default:
                          return '/landing';
                      }
                    }}
                    data-cy="create-event-select"
                  />
                </Box>
              </FdDelayed>
            )}
          </Box>
          <EventTable
            rows={allEvents?.filter(
              (e) => !eventType || e?.eventType === eventType,
            )}
            refetchAssessmentData={refetchAssessmentData}
            filterType={eventType}
          />
        </FdSkeleton>
      ),
    },
  ];

  return (
    <Box>
      <FdBreadcrumbHeader />
      <BasePage data-cy="welcome-card">
        <AffiliationLinkStatusModal />
        <Box className="flex items-center gap-x-3 mt-1">
          <HomeIcon />
          <FdTypography
            variant="h3"
            color="primary"
            style={{ fontFamily: 'Raitor', fontWeight: 400 }}
          >
            Home
          </FdTypography>
          <Box
            className="flex items-center rounded-xl px-3 py-1"
            sx={{ bgcolor: 'primary.main' }}
          >
            <FdTypography variant="body2" color="white">
              Manage Mode
            </FdTypography>
          </Box>
          <Box className="flex items-center gap-x-3 ml-auto">
            <AffiliationTag />
            <SquadTag />
          </Box>
        </Box>
        {hasManageEvents && (
          <FdCard
            elevation={0}
            style={{ marginTop: '0.8rem' }}
            heading={
              <Box
                display="flex"
                justifyContent="space-between"
                style={{ marginBottom: '-32px' }}
              >
                <Box className="flex flex-col mb-2">
                  <FdTypography variant="h3">Events</FdTypography>
                  <FdTypography variant="body2" color="secondary">
                    This table displays all events within your organisation. The
                    &apos;FD Trainings&apos; tab lists all FifthDomain training
                    events, while the &apos;Organisation Events&apos; tab shows
                    events created by your organisation.
                  </FdTypography>
                </Box>
              </Box>
            }
          >
            {allEvents?.length === 0 &&
            !listAssessmentsByOrgLoading &&
            !listCoursesByOrgIdLoading ? (
              <Box
                height="440px"
                display="flex"
                justifyContent="center"
                alignItems="center"
              >
                <img src={NoContentImage} alt="no-content-data" />
              </Box>
            ) : (
              <FdTab
                label={tabLabel?.filter((item) => item)}
                variant="fullWidth"
              />
            )}
          </FdCard>
        )}

        {pricingTier !== 'STARTER' && hasManageContent && (
          <Grid container spacing={2} style={{ paddingTop: '5px' }}>
            {hasManageUser && (
              <Grid item xs={3}>
                <FdSkeleton
                  loading={listCoursesByOrgIdLoading}
                  height="530px"
                  animation="wave"
                >
                  <OrgUsers
                    listCoursesByOrgIdData={listCoursesByOrgIdData}
                    eventsData={eventsData}
                  />
                </FdSkeleton>
              </Grid>
            )}
            <Grid
              item
              // Tempory hiding as per SD-3254 will be needed in future
              // xs={isAdmin ? (hasManageUser ? 7 : 10) : hasManageUser ? 9 : 12}
              xs={hasManageUser ? 9 : 12}
            >
              <Summary
                loading={
                  listTasksByOrgIdLoading ||
                  allTasksAvailableLoading ||
                  templatesLoading
                }
                data={[
                  {
                    value: 'Your Content',
                  },
                  {
                    value: allTasks?.length,
                    description: 'No. of Challenges',
                  },
                  {
                    value: [
                      ...new Set(
                        allTasks
                          ?.map((e) => e.specialty?.name)
                          .filter((u) => u),
                      ),
                    ].length,
                    description: 'No. of Professional Specialties',
                  },
                  {
                    value: Authorization.canCreate(globalSnap.permissions) && (
                      <FdButton
                        onClick={() => {
                          singleSpa.navigateToUrl('/tasks');
                        }}
                      >
                        view
                      </FdButton>
                    ),
                  },
                ]}
              />
              <CompositionGraphs
                allTasks={allTasks}
                loading={
                  listTasksByOrgIdLoading ||
                  allTasksAvailableLoading ||
                  templatesLoading
                }
              />
            </Grid>
          </Grid>
        )}
        <FdModal
          title={
            <Box display="flex" justifyContent="center">
              {`Select ${openModal} Creation Option`}
            </Box>
          }
          description={
            <>
              <FdTypography variant="body1" color="secondary">
                {`Please select whether you wish to create an ${openModal} using a
            FifthDomain template or create your own custom ${openModal}.`}
              </FdTypography>
              <Box display="flex" flexDirection="row" mt={2}>
                <FdCard
                  style={{
                    border:
                      selectedTemplateOption === 'withTemplate'
                        ? '2px solid #1976D2'
                        : 'none',
                  }}
                >
                  <BallotIcon />
                  <FdTypography variant="subtitle1">{`Create ${openModal} using Template`}</FdTypography>
                  <Box my={2}>
                    <FdTypography
                      variant="body2"
                      color="secondary"
                    >{`Choose a ready-to-go FifthDomain ${openModal} template, fill in required details, and invite your participants!`}</FdTypography>
                  </Box>
                  <FdButton
                    fullWidth
                    onClick={() => {
                      setSelectedTemplateOption('withTemplate');
                    }}
                  >
                    select
                  </FdButton>
                </FdCard>
                <FdCard
                  style={{
                    marginLeft: '24px',
                    border:
                      selectedTemplateOption === 'noTemplate'
                        ? '2px solid #1976D2'
                        : 'none',
                  }}
                >
                  <CreateIcon />
                  <FdTypography variant="subtitle1">{`Create Custom ${openModal} `}</FdTypography>
                  <Box my={2}>
                    <FdTypography
                      variant="body2"
                      color="secondary"
                    >{`Create a new custom ${openModal} that is fit-for-purpose for your organisation’s unique assessment needs!`}</FdTypography>
                  </Box>
                  <FdButton
                    fullWidth
                    onClick={() => {
                      setSelectedTemplateOption('noTemplate');
                    }}
                  >
                    select
                  </FdButton>
                </FdCard>
              </Box>
            </>
          }
          confirm="PROCEED"
          dismiss="CANCEL"
          open={openModal}
          onConfirm={async () => {
            const urlValue = {
              // hiding competitions temporary
              // Competition: 'competitions',
              Assessment: 'assessor',
            };
            const url =
              selectedTemplateOption === 'withTemplate'
                ? `/${urlValue[openModal]}/create/template`
                : `/${urlValue[openModal]}/create`;
            singleSpa.navigateToUrl(url);

            setOpenModal(undefined);
          }}
          onDismiss={() => {
            setOpenModal(undefined);
            setSelectedTemplateOption(null);
            setCreateEventType(undefined);
          }}
        />
      </BasePage>
    </Box>
  );
};
export default AdminLandingPage;
