import React, { useState } from 'react';
import { useQuery, gql } from '@apollo/client';
import { useForm, FormProvider } from 'react-hook-form';
import { yupResolver } from '@hookform/resolvers/yup';
import {
  BasePage,
  useSnapshot,
  globalStore,
  FdLoadingSpinner,
} from '@fifthdomain/fe-shared';
import { getUserById, listAffliationsByUserId } from '../graphql/queries';
import {
  Details,
  Accomplishments,
  PasswordChange,
  ThemeSelection,
} from '../components/ManageUserProfile';
import {
  initialValues,
  validationSchema,
} from '../validation-schemas/ManageUserProfile';
import {
  WORK_STATUS,
  GENDER,
  WORKING_ARRANGEMENT,
  NEURODIVERSITY,
  RURAL_REMOTE,
  ETHNICITY,
  ACCOMPLISHMENT_TYPES,
} from '../constants';
import { getValueByKey } from '../shared/utils/objectUtils';
import AffiliationInfoCard from '../components/Affiliated/AffiliationInfoCard';

const ManageUserProfile = () => {
  const globalSnap = useSnapshot(globalStore);
  const [tempOrgName] = useState(globalSnap?.orgName);
  const reactHookFormMethods = useForm({
    defaultValues: initialValues,
    resolver: yupResolver(validationSchema),
    mode: 'all',
  });
  const { reset, getValues } = reactHookFormMethods;

  const { data: userData, refetch: refetchUserData } = useQuery(
    gql(getUserById),
    {
      variables: {
        id: globalSnap?.userId,
      },
      skip: !globalSnap?.userId,
      fetchPolicy: 'network-only',
      onCompleted: (data) => {
        const user = data?.getUserById?.items[0];
        reset({
          ...user,
          theme: user.theme || 'DARK',
          specialThemes:
            data?.getUserById.items[0]?.org?.specialThemes?.items?.map((t) => {
              return {
                value: t?.theme?.name,
                label: t?.theme?.name,
              };
            }),
          workStatus: getValueByKey(WORK_STATUS, user?.workStatus),
          gender: getValueByKey(GENDER, user?.gender),
          workArrangement: getValueByKey(
            WORKING_ARRANGEMENT,
            user?.workArrangement,
          ),
          neurodiversity: getValueByKey(NEURODIVERSITY, user?.neurodiversity),
          ethnicity: getValueByKey(ETHNICITY, user?.ethnicity),
          remote: getValueByKey(RURAL_REMOTE, user?.remote),
          salaryRange: [user?.minSalary, user?.maxSalary],
          linkedIn: user?.socialMedias?.items?.find(
            (item) => item?.type === 'LINKEDIN',
          )?.url,
          gitlab: user?.socialMedias?.items?.find(
            (item) => item?.type === 'GITHUB',
          )?.url,
          website: user?.socialMedias?.items?.find(
            (item) => item?.type === 'WEBSITE',
          )?.url,
          avatar: user?.key
            ? new File([''], user?.key?.match(/[^/]+$/)[0], {
                type: 'image/png',
              })
            : undefined,
          addAccomplishment:
            user?.accomplishments?.items?.map((item) => ({
              ...item,
              accId: item?.id,
              accName: item?.name,
              type: getValueByKey(ACCOMPLISHMENT_TYPES, item?.type),
              startYear: item?.startYear || '',
              endYear: item?.endYear || '',
              yearAwarded: item?.yearAwarded || '',
              currentYear: item?.endYear === String(new Date().getFullYear()),
            })) || [],
        });
      },
    },
  );

  // get affiliation status
  const { data: affiliationData, refetch: affiliationDataRefetch } = useQuery(
    gql(listAffliationsByUserId),
    {
      variables: {
        userId: globalSnap?.userId,
        skip: !globalSnap?.affiliated,
      },
    },
  );

  if (!globalSnap?.userId) {
    return <FdLoadingSpinner />;
  }

  const affiliationDetails =
    affiliationData?.listAffliationsByUserId?.items?.filter(
      (a) => a?.status === 'ACTIVE',
    )[0] || {};

  const refetchQueries = () => {
    refetchUserData();
    affiliationDataRefetch();
  };

  return (
    <BasePage>
      <FormProvider {...reactHookFormMethods}>
        <form>
          {/* <Grid container spacing={2}>
            <Grid item xs={7}> */}
          <Details
            previousAlias={userData?.getUserById?.items[0].alias}
            refetchUserData={refetchUserData}
          />
          <Accomplishments refetchUserData={refetchUserData} />
          {globalSnap.isAffiliated &&
            affiliationDetails?.status === 'ACTIVE' && (
              <AffiliationInfoCard
                refetchQueries={refetchQueries}
                tempOrgName={tempOrgName}
              />
            )}
          <PasswordChange />
          {globalSnap?.userType === 'PARTICIPANT' && (
            <ThemeSelection
              currentTheme={getValues()?.theme}
              userId={globalSnap?.userId}
              specialThemes={getValues('specialThemes') || []}
            />
          )}
          {/* <Resume userRefetch={userRefetch} /> 
            </Grid>
              <Grid item xs={5}>
              <SocialMedia />
              <JobPool />
              <Diversity /> 
              <Logistics />
            </Grid>
          </Grid>
           */}
        </form>
      </FormProvider>
    </BasePage>
  );
};
export default ManageUserProfile;
