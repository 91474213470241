import React, { useState } from 'react';
import { Box } from '@mui/material';
import { useMutation, gql } from '@apollo/client';
import { Controller, useFormContext } from 'react-hook-form';
import {
  FdCard,
  FdTextField,
  FdTypography,
  FdButton,
} from '@fifthdomain/fe-shared';
import {
  warningToastMessage,
  successToastMessage,
  errorToastMessage,
} from '../../shared/utils/toast';
import { updateOrg } from '../../graphql/mutations';

const OrgDetails = () => {
  const [editMode, setEditMode] = useState(false);
  const { control, getValues, reset, trigger } = useFormContext();
  const [updateOrgMutation, { loading: updateOrgLoading }] = useMutation(
    gql(updateOrg),
    {
      onError: ({ graphQLErrors }) => {
        errorToastMessage(graphQLErrors[0]?.message);
      },
    },
  );

  return (
    <FdCard
      heading={
        <Box display="flex" justifyContent="space-between">
          <FdTypography variant="h3">Key Details</FdTypography>
          {editMode ? (
            <Box display="flex">
              <FdButton
                variant="secondary"
                size="small"
                onClick={() => {
                  reset();
                  setEditMode(false);
                  warningToastMessage('Updates not saved');
                }}
                style={{
                  marginRight: '8px',
                }}
              >
                CANCEL
              </FdButton>
              <FdButton
                variant="primary"
                size="small"
                onClick={async () => {
                  const details = await trigger(['name']);
                  if (details) {
                    const { id, name } = getValues();
                    await updateOrgMutation({
                      variables: {
                        input: {
                          id,
                          name,
                        },
                      },
                      onCompleted: (_data) => {
                        reset({
                          ..._data?.updateOrg,
                        });
                        successToastMessage('Updates saved!');
                      },
                    });
                    setEditMode(false);
                  }
                }}
              >
                {updateOrgLoading ? 'SAVING...' : 'SAVE'}
              </FdButton>
            </Box>
          ) : (
            <FdButton
              variant="primary"
              size="small"
              onClick={() => setEditMode(true)}
            >
              Edit
            </FdButton>
          )}
        </Box>
      }
      data-cy="details"
    >
      {editMode ? (
        <Controller
          control={control}
          name="name"
          render={({ field: { ref, ...rest }, fieldState: { error } }) => (
            <Box mt={1} mb={3}>
              <FdTextField
                id="name"
                label="Organisation Name"
                required
                fullWidth
                error={error}
                helperText={error && error.message}
                data-cy="name"
                {...rest}
                inputRef={ref}
              />
            </Box>
          )}
        />
      ) : (
        <Box>
          <FdTypography variant="subtitle1">Organisation Name</FdTypography>
          <FdTypography variant="body1" color="secondary">
            {getValues()?.name}
          </FdTypography>
        </Box>
      )}
      <Box mt={3}>
        <FdTypography variant="subtitle1">Organisation Alias</FdTypography>
        <FdTypography variant="body1" color="secondary">
          {getValues()?.alias}
        </FdTypography>
      </Box>
    </FdCard>
  );
};

export default OrgDetails;
