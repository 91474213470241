import React from 'react';
import PropTypes from 'prop-types';
import { Box, Divider } from '@mui/material';
import _ from 'lodash';
import { gql } from '@apollo/client';
import * as singleSpa from 'single-spa';
import {
  FdTypography,
  FdCard,
  FdButton,
  FdAccordion,
  useQueryRecursive,
  useSnapshot,
  globalStore,
  FdIconWithTooltip,
  FdSkeleton,
  Authorization,
  PERMISSIONS_AFFILIATED_DISPLAY,
} from '@fifthdomain/fe-shared';
import {
  listAffliationRequestsByOrgId,
  listNonAffliatedUserEventsByOrgId,
  listUserAssessments,
} from '../../queries/customQueries';
import { listInvitedUsersByOrg } from '../../graphql/queries';
import { snakeCaseToTitleCase } from '../../shared/utils/stringUtils';

const LabelValue = ({ label, value, caption, variant }) => (
  <Box
    className={`flex flex-col justify-center ${
      variant === 'body' ? 'my-4' : 'my-2'
    }`}
  >
    <Box className="flex items-center justify-between">
      <FdTypography variant={variant}>{label}</FdTypography>
      <FdTypography variant={variant}>{value > 0 ? value : '-'}</FdTypography>
    </Box>
    {caption && (
      <FdTypography variant="captiontext1" color="secondary">
        {caption}
      </FdTypography>
    )}
  </Box>
);

LabelValue.defaultProps = {
  caption: undefined,
  variant: 'body',
};

LabelValue.propTypes = {
  label: PropTypes.string.isRequired,
  value: PropTypes.string.isRequired,
  caption: PropTypes.string,
  variant: PropTypes.string,
};

const OrgUsers = ({ listCoursesByOrgIdData, eventsData }) => {
  const globalSnap = useSnapshot(globalStore);
  const viewManageUserPermissions = Authorization.canManageUsers(
    globalSnap?.permissions,
  );

  const { data: nonAffiliatedUsersData, loading: nonAffiliatedUsersLoading } =
    useQueryRecursive(gql(listNonAffliatedUserEventsByOrgId), {
      variables: {
        orgId: globalSnap.orgId,
        limit: 500,
      },
      skip: !globalSnap.orgId,
    });

  const { data: affiliatedRequestsData, loading: affiliatedRequestsLoading } =
    useQueryRecursive(gql(listAffliationRequestsByOrgId), {
      variables: {
        orgId: globalSnap.orgId,
        status: { eq: 'ACCEPTED' },
        limit: 500,
      },
      skip: !globalSnap.orgId,
    });

  const { data: invitedUsersData, loading: invitedUsersLoading } =
    useQueryRecursive(gql(listInvitedUsersByOrg), {
      variables: {
        orgId: globalSnap.orgId,
        filter: { accepted: { eq: false } },
        limit: 500,
      },
      skip: !globalSnap.orgId,
    });

  const { data: listUserAssessmentsData, loading: listUserAssessmentsLoading } =
    useQueryRecursive(gql(listUserAssessments), {
      variables: {
        filter: {
          status: { ne: 'REMOVED' },
        },
        limit: 500,
      },
      staleTime: { hours: 24 },
    });

  const allAffiliatedUsers =
    affiliatedRequestsData?.listAffliationRequestsByOrgId?.items || [];

  const allNonAffiliatedUsersByEmail = _.groupBy(
    nonAffiliatedUsersData?.listNonAffliatedUserEventsByOrgId?.items,
    'email',
  );

  const allNonAffiliatedUsers = Object.keys(allNonAffiliatedUsersByEmail).map(
    (user) => {
      const userData = allNonAffiliatedUsersByEmail[user];
      return {
        id: userData[0]?.email,
        registered: userData[0]?.user?.name,
      };
    },
  );
  const nonAffiliatedUsersCount =
    allNonAffiliatedUsers?.filter((nf) => nf?.registered).length || 0;

  const courseUser =
    listCoursesByOrgIdData?.listCoursesByOrgId?.items
      ?.map((item) => item?.courseUsers?.items?.map((i) => i.userId))
      .flat() || [];

  const eventParticipantCount = (_eventType) => {
    const eventIds = eventsData
      ?.filter((item) => item?.participantEventType === _eventType)
      ?.map((item) => item?.id);

    const registeredEventParticipant = [
      ...new Set(
        listUserAssessmentsData?.listUserAssessments?.items
          ?.filter((item) =>
            eventIds?.includes(item?.userAssessmentAssessmentId),
          )
          ?.map((_item) => _item?.userId) || [],
      ),
    ].length;
    const invitedEventParticipant = [
      ...new Set(
        invitedUsersData?.listInvitedUsersByOrg?.items
          ?.filter(
            (item) => eventIds?.includes(item?.assessmentId) && !item?.accepted,
          )
          .map((_item) => _item.email) || [],
      ),
    ].length;
    return {
      registeredEventParticipant,
      invitedEventParticipant,
    };
  };

  const {
    registeredEventParticipant: assessRegistered,
    invitedEventParticipant: assessInvited,
  } = eventParticipantCount('ASSESSMENT');
  const {
    registeredEventParticipant: compRegistered,
    invitedEventParticipant: compInvited,
  } = eventParticipantCount('COMPETITION');

  const accountOwnersCount =
    allAffiliatedUsers?.filter((u) =>
      u?.user?.permissions?.includes('ORG_ADMIN'),
    )?.length || 0;

  const userPermissions = (
    <Box className="flex flex-col mx-5">
      <LabelValue
        label="Account owner:"
        value={accountOwnersCount > 0 ? accountOwnersCount : '-'}
        variant="captiontext1"
      />
      <Divider />
      {Object.keys(PERMISSIONS_AFFILIATED_DISPLAY).map((key) => {
        const keyValue = PERMISSIONS_AFFILIATED_DISPLAY[key];
        const permissionCount =
          allAffiliatedUsers?.filter((u) =>
            u?.user?.permissions?.includes(keyValue),
          )?.length || 0;
        const value = snakeCaseToTitleCase(
          keyValue === 'CREATE' ? 'Create Content' : keyValue,
        );

        return (
          <LabelValue
            label={`${value}:`}
            value={permissionCount > 0 ? permissionCount : '-'}
            variant="captiontext1"
          />
        );
      })}
    </Box>
  );

  return (
    <FdSkeleton
      loading={
        invitedUsersLoading ||
        nonAffiliatedUsersLoading ||
        listUserAssessmentsLoading ||
        affiliatedRequestsLoading
      }
      height="534px"
    >
      <FdCard
        variant="outlined"
        style={{
          height: '534px',
          overflowY: 'auto',
        }}
      >
        <Box className="flex justify-between items-center">
          <FdTypography variant="subtitle1">
            Your Organisation Users
          </FdTypography>
          {viewManageUserPermissions && (
            <FdButton
              onClick={() => {
                singleSpa.navigateToUrl('/user-management/affiliated-users');
              }}
            >
              view
            </FdButton>
          )}
        </Box>
        <LabelValue
          label="Affiliated Users"
          value={allAffiliatedUsers?.length}
        />
        <FdAccordion
          showToggleButton={false}
          // eslint-disable-next-line react/no-unstable-nested-components
          summary={() => (
            <FdTypography variant="body2">
              Permissions and no. of users
            </FdTypography>
          )}
          // eslint-disable-next-line react/no-unstable-nested-components
          content={() => userPermissions}
          endAdornment
        />
        <LabelValue
          label="Non-Affiliated Users"
          value={nonAffiliatedUsersCount}
        />
        <Divider />
        <Box mt={2}>
          <Box className="flex">
            <FdTypography variant="subtitle1">
              Users invited to Events
            </FdTypography>
            <FdIconWithTooltip
              title={
                <>
                  The “Users invited to Events “ list shows total number of
                  users in each event type including both Affiliated and
                  Non-affiliated users in your organisation.
                  <br />
                  The number of users is only for the events hosted by your
                  organisation.
                </>
              }
            />
          </Box>
          <LabelValue
            label="Competitions"
            value={compInvited + compRegistered}
            caption={`Invited : ${compInvited}, Registered : ${compRegistered}`}
          />
          <LabelValue
            label="Assessments"
            value={assessInvited + assessRegistered}
            caption={`Invited : ${assessInvited}, Registered : ${assessRegistered}`}
          />
          <LabelValue label="Courses" value={[...new Set(courseUser)].length} />
        </Box>
      </FdCard>
    </FdSkeleton>
  );
};

OrgUsers.propTypes = {
  listCoursesByOrgIdData: PropTypes.shape({
    listCoursesByOrgId: PropTypes.shape({
      items: PropTypes.arrayOf(
        PropTypes.shape({
          id: PropTypes.string,
          userId: PropTypes.string,
        }),
      ),
    }),
  }),
  eventsData: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ),
};

OrgUsers.defaultProps = {
  listCoursesByOrgIdData: {},
  eventsData: [],
};

export default OrgUsers;
