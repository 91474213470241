import React, { useEffect, useMemo, useState } from 'react';
import PropTypes from 'prop-types';
import { useMutation, gql } from '@apollo/client';
import { Box } from '@mui/material';
import {
  FdCard,
  FdButton,
  FdTypography,
  FdRadioGroup,
  setTheme,
  globalStore,
} from '@fifthdomain/fe-shared';
import { THEMES } from '../../constants';
import {
  errorToastMessage,
  successToastMessage,
} from '../../shared/utils/toast';
import { updateUser } from '../../graphql/mutations';

const ThemeSelection = ({ userId, currentTheme, specialThemes }) => {
  const [editMode, setEditMode] = useState(false);
  const [appTheme, setAppTheme] = useState();

  const themesList = useMemo(() => {
    return [...THEMES, ...specialThemes];
  }, [specialThemes]);

  useEffect(() => {
    setAppTheme(themesList.find((t) => t.value === currentTheme));
  }, [currentTheme, themesList]);

  const [updateUserThemeMutation, { loading: updateUserThemeLoading }] =
    useMutation(gql(updateUser));

  const onThemeChange = async (theme) => {
    await updateUserThemeMutation({
      variables: {
        input: {
          id: userId,
          theme,
        },
      },
      refetchQueries: ['GetUserById'],
      onCompleted: () => {
        globalStore.theme = theme.toLowerCase();
        setTheme(theme.toLowerCase());
        successToastMessage('Theme changed successfully');
        setEditMode(false);
      },
      onError: () => {
        errorToastMessage('Error in changing theme');
        setEditMode(false);
      },
    });
  };

  return (
    <Box>
      <FdCard
        variant="outlined"
        heading={
          <Box display="flex" justifyContent="space-between">
            <FdTypography variant="h3">Theme</FdTypography>
            <Box display="flex" justifyContent="space-between">
              {editMode ? (
                <Box display="flex" flexDirection="row">
                  <Box pr={1}>
                    <FdButton
                      variant="secondary"
                      size="small"
                      onClick={() => {
                        setEditMode(false);
                        setTheme(currentTheme.toLowerCase());
                        setAppTheme(
                          themesList.find((t) => t.value === currentTheme),
                        );
                      }}
                      disabled={updateUserThemeLoading}
                    >
                      CANCEL
                    </FdButton>
                  </Box>
                  <Box>
                    <FdButton
                      variant="primary"
                      size="small"
                      onClick={() => {
                        onThemeChange(appTheme?.value);
                      }}
                      disabled={updateUserThemeLoading}
                    >
                      {updateUserThemeLoading ? 'SAVING...' : 'SAVE'}
                    </FdButton>
                  </Box>
                </Box>
              ) : (
                <FdButton
                  variant="primary"
                  size="small"
                  onClick={() => setEditMode(true)}
                >
                  CHANGE
                </FdButton>
              )}
            </Box>
          </Box>
        }
      >
        <Box mt={1}>
          {editMode ? (
            <Box ml={1}>
              <FdRadioGroup
                id="themeType"
                options={themesList.map((t) => t.label)}
                defaultValue={
                  themesList.find((t) => t.value === currentTheme)?.label
                }
                onChange={(v) => {
                  const theme = themesList.find((t) => t.label === v.value);
                  setTheme(theme?.value.toLowerCase());
                  setAppTheme(theme);
                }}
                fullWidth
              />
            </Box>
          ) : (
            <FdTypography variant="body1" color="secondary">
              {themesList.find((t) => t.value === appTheme?.value)?.label}
            </FdTypography>
          )}
        </Box>
      </FdCard>
    </Box>
  );
};

ThemeSelection.propTypes = {
  userId: PropTypes.string.isRequired,
  currentTheme: PropTypes.string.isRequired,
  specialThemes: PropTypes.arrayOf(PropTypes.object),
};

ThemeSelection.defaultProps = {
  specialThemes: [],
};

export default ThemeSelection;
