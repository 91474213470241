import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  useAuthSession,
  useSnapshot,
  globalStore,
  Authorization,
  orgDetailsPermissions,
} from '@fifthdomain/fe-shared';

const AdminOrgProfileRoute = ({ component: Component, ...rest }) => {
  const { user } = useAuthSession();
  const globalSnap = useSnapshot(globalStore);

  const hasManagePermissions = Authorization.hasPermission(
    globalSnap?.permissions,
    orgDetailsPermissions,
  );

  if (globalSnap.loading || !globalSnap.orgId) {
    return null;
  }

  return (
    <Route
      {...rest}
      render={(props) => {
        const type = globalSnap.userType;
        if (user && type === 'ADMIN' && !hasManagePermissions) {
          return <Redirect to="/landing" />;
        }
        return <Component {...rest} {...props} />;
      }}
    />
  );
};

AdminOrgProfileRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
};

export default AdminOrgProfileRoute;
