import React from 'react';
import PropTypes from 'prop-types';
import { Box, useTheme } from '@mui/material';
import {
  FdTable,
  FdTooltip,
  FdTypography,
  FdButton,
} from '@fifthdomain/fe-shared';
import { productStyle } from '../../shared/utils/layout';
import CardProgressBar from './CardProgressBar';
import ChatMessageIndicator from './ChatIndicator';

const ParticipantEventTable = ({ rows, onEnterClick }) => {
  const theme = useTheme();
  const actions = [
    {
      // eslint-disable-next-line react/no-unstable-nested-components
      CustomElement: (row) => {
        const { eventAction } = row?.rowData || {};
        return (
          <FdButton
            variant="primary"
            size="small"
            onClick={() => onEnterClick(row?.rowData)}
          >
            {eventAction}
          </FdButton>
        );
      },
      width: 150,
    },
  ];

  return (
    <FdTable
      toolbarSettings={{
        hide: true,
      }}
      actions={actions}
      hideFooter
      autoHeight
      rows={rows}
      columns={[
        {
          field: 'eventName',
          flex: 2,
          headerName: 'Event Name',
          valueGetter: (params) => params.row.title,
          renderCell: (params) => {
            const { row } = params;
            return (
              <Box display="flex" minWidth="391px">
                <Box
                  display="inline-block"
                  ml={1}
                  style={{
                    ...productStyle(row.contentType?.toUpperCase(), theme),
                    paddingTop: 0,
                  }}
                >
                  {row.icon}
                </Box>
                <Box className="flex flex-col justify-around ml-3">
                  <Box width={391}>
                    <FdTooltip title={row.title?.length > 40 ? row.title : ''}>
                      <span>
                        <FdTypography
                          variant="body2"
                          fontWeight="medium"
                          className="overflow-hidden whitespace-nowrap overflow-ellipsis mb-1"
                        >
                          {row.title}
                        </FdTypography>
                      </span>
                    </FdTooltip>
                  </Box>
                  <Box className="flex items-center">
                    {row.contentType === 'course' &&
                      row.courseModulesCount > 0 && (
                        <Box
                          display="inline-block"
                          ml={1}
                          style={{
                            ...productStyle('COURSE', theme),
                            padding: '1px 4px',
                          }}
                        >
                          <FdTypography variant="captiontext2" color="black">
                            {`${row.courseModulesCount} lessons`}
                          </FdTypography>
                        </Box>
                      )}
                    {row.contentType === 'assessment' && (
                      <Box display="flex" ml={1}>
                        <span
                          style={{
                            ...productStyle('ASSESSMENT', theme),
                            padding: '1px 4px',
                          }}
                        >
                          <FdTypography variant="captiontext2" color="black">
                            {`Duration: ${row.duration}`}
                          </FdTypography>
                        </span>
                      </Box>
                    )}
                    {row.contentType === 'competition' && (
                      <Box display="flex" ml={1}>
                        <span
                          style={{
                            ...productStyle('COMPETITION', theme),
                            padding: '1px 4px',
                          }}
                        >
                          <FdTypography variant="captiontext2" color="black">
                            {`${row.type} Competition`}
                          </FdTypography>
                        </span>
                      </Box>
                    )}
                    {row.contentType === 'training' && (
                      <Box className="flex gap-x-1">
                        <Box display="flex">
                          <span
                            style={{
                              ...productStyle('TRAINING', theme),
                              padding: '1px 4px',
                              marginRight: '8px',
                            }}
                          >
                            <FdTypography variant="captiontext2" color="black">
                              Training
                            </FdTypography>
                          </span>
                        </Box>
                        <Box display="flex">
                          <span
                            style={{
                              ...productStyle('TRAINING', theme),
                              padding: '1px 4px',
                              marginRight: '8px',
                            }}
                          >
                            <FdTypography variant="captiontext2" color="black">
                              {`${row?.tasksCount} Challenges`}
                            </FdTypography>
                          </span>
                        </Box>
                      </Box>
                    )}
                    {row.eventAction === 'Continue' && (
                      <Box display="flex" ml={1}>
                        <CardProgressBar content={row} linearProgress />
                      </Box>
                    )}
                  </Box>
                </Box>
              </Box>
            );
          },
        },
        {
          field: 'unReadMessageCount',
          maxWidth: 150,
          unSort: true,
          headerAlign: 'center',
          filterable: false,
          align: 'center',
          headerName: '',
          valueGetter: (params) => params.row.unReadMessageCount,
          renderCell: (params) => {
            return (
              params.row.contentType === 'course' &&
              params.row.unReadMessageCount > 0 && (
                <ChatMessageIndicator
                  messageCount={params.row.unReadMessageCount}
                />
              )
            );
          },
        },
      ]}
    />
  );
};
ParticipantEventTable.propTypes = {
  rows: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
  onEnterClick: PropTypes.func.isRequired,
};

export default ParticipantEventTable;
