import React from 'react';
import PropTypes from 'prop-types';
import { Box } from '@material-ui/core';
import * as singleSpa from 'single-spa';
import { FdTypography, FdCard, FdButton, FdTab } from '@fifthdomain/fe-shared';
import LabelWithTooltip from './LabelWithTooltip';
import NoEventImage from '../../shared/images/no-events.svg';
import ParticipantEventTable from './ParticipantEventTable';

const Events = ({ allEvents, onEnterClick }) => {
  const lastAssigned =
    allEvents
      ?.map((item) => ({
        ...item,
        eventAction: 'Enter',
      }))
      ?.sort((a, b) => new Date(b.createdAt) - new Date(a.createdAt))
      ?.slice(0, 5) || [];

  const lastActives =
    allEvents
      ?.map((item) => ({
        ...item,
        eventAction: 'Continue',
      }))
      ?.sort((a, b) => new Date(b.lastActive) - new Date(a.lastActive))
      ?.slice(0, 5) || [];

  return (
    <FdCard
      variant="outlined"
      heading={
        <Box className="flex justify-between items-center">
          <LabelWithTooltip
            label="Events"
            tooltipText="The Latest Active tab displays up to five events you have most recently engaged with. The Latest Assigned tab shows the five most recent events assigned to you. Click View All Events to see all your assigned events in detail."
            labelVariant="body1"
          />
          <FdButton
            size="small"
            variant="secondary"
            onClick={() => {
              singleSpa.navigateToUrl('/landing/landing-homepage/my-events');
            }}
          >
            View all events
          </FdButton>
        </Box>
      }
    >
      {allEvents?.length > 0 ? (
        <FdTab
          label={[
            {
              label: 'Latest Active',
              path: '/inActiveEvents',
              index: 0,
              data: (
                <ParticipantEventTable
                  rows={lastActives}
                  onEnterClick={onEnterClick}
                />
              ),
            },
            {
              label: 'Latest Assigned',
              path: '/inAssignedEvents',
              index: 0,
              data: (
                <ParticipantEventTable
                  rows={lastAssigned}
                  onEnterClick={onEnterClick}
                />
              ),
            },
          ]}
          style={{ marginTop: '-25px' }}
        />
      ) : (
        <Box mt={1} className="flex flex-col items-center my-1">
          <FdTypography variant="body1">
            When you are assigned a course, competition or an assessment, it
            will populate here.
          </FdTypography>
          <Box my={4}>
            <img src={NoEventImage} alt="no-events" />
          </Box>
        </Box>
      )}
    </FdCard>
  );
};

Events.propTypes = {
  allEvents: PropTypes.arrayOf(
    PropTypes.shape({
      id: PropTypes.string,
    }),
  ).isRequired,
  onEnterClick: PropTypes.func.isRequired,
};

export default Events;
