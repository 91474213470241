import React from 'react';
import { Route, Redirect } from 'react-router-dom';
import PropTypes from 'prop-types';
import {
  useAuthSession,
  useSnapshot,
  globalStore,
} from '@fifthdomain/fe-shared';

const ParticipantRoute = ({ component: Component, ...rest }) => {
  const { user } = useAuthSession();
  const globalSnap = useSnapshot(globalStore);

  return (
    <Route
      {...rest}
      render={(props) => {
        const type = globalSnap.userType;
        if (user && type === 'PARTICIPANT') {
          return <Component {...rest} {...props} />;
        }
        if (user && ['ADMIN', 'MANAGER'].includes(type)) {
          return <Redirect to="/landing" />;
        }
        return null;
      }}
    />
  );
};

ParticipantRoute.propTypes = {
  component: PropTypes.oneOfType([PropTypes.string, PropTypes.func]).isRequired,
};

export default ParticipantRoute;
